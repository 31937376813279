import * as React from "react";

import { ScrollArrow, ScrollHolder } from "./Scrolling";
import * as styles from "./styles.module.css";

interface State {}

interface parentProps {
  bgColour: string;
  title: string;
  pageId: string;
  next: string;
}

export class TitlePage extends React.Component<parentProps, State> {
  render() {
    return (
      <ScrollHolder
        id={this.props.pageId}
        className={styles.window}
        style={{ backgroundColor: this.props.bgColour }}
      >
        <div className={`${styles.top}`}></div>
        <div
          className={`${styles.contentHolder} ${styles.titlePageContentHolder} d-flex align-items-center`}
        >
          <h1
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: this.props.title }}
          ></h1>
        </div>
        <ScrollArrow target={this.props.next} direction="down" />
      </ScrollHolder>
    );
  }
}

export class TitlePage2 extends React.Component<parentProps, State> {
  render() {
    return (
      <ScrollHolder
        id={this.props.pageId}
        className={styles.window}
        style={{ backgroundColor: this.props.bgColour }}
      >
        <div className={`${styles.top}`}></div>
        <div
          className={`${styles.contentHolder} ${styles.titlePageContentHolder} d-flex align-items-center`}
        >
          <h1
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: this.props.title }}
          ></h1>
        </div>
        <ScrollArrow target={this.props.next} direction="down" />
      </ScrollHolder>
    );
  }
}
