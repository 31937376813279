import * as React from "react";
import { connect } from "react-redux";

import classNames from "classnames";
import { Link } from "react-router-dom";
import { IAnaf, ITag, ICrumb } from "../../reducers/anafReducer";
import { techniqueList } from "../../tools/tagTools";
import { tagTypes } from "../../actions/actionTypes";
import { removeTag, toggleTopSearch } from "../../actions/anafActions";
import Topsearch from "./Topsearch";
import { stripMarks } from "../pages/ContentPage";

import { SVGCross, SVGArrowLeft, SVGPlus } from "../../tools/svgTools";
import { translate } from "../../tools/translateTools";

import * as styles from "./styles.module.scss";

interface State {
  showSearch: boolean;
}

interface parentProps {
  location: any;
}

interface StoreProps {
  anaf: IAnaf;
}

interface DispatchProps {
  removeTag: Function;
  toggleTopSearch: Function;
}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
  };
};

const mapDispatchToProps = (dispatch) => ({
  removeTag: (type: tagTypes, value: number) => {
    dispatch(removeTag(type, value));
  },
  toggleTopSearch: (val: boolean) => {
    dispatch(toggleTopSearch(val));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

export class Breadcrumbs extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showSearch: false,
    };
  }

  render() {
    const breadcrumbStyle = {
      zIndex: this.props.anaf.searchOpen ? 0 : 9,
    };

    return (
      <div
        style={breadcrumbStyle}
        className={`${styles.crumbs_holder} content`}
      >
        {this.crumbs()} {this.showTags() ? this.tags() : <span></span>}
      </div>
    );
  }

  tags() {
    let techniques = techniqueList();

    let taglist = new Array();

    this.props.anaf.tags.forEach((tag, index) => {
      let newtag = this.Tag(tag, index);
      taglist.push(newtag);
    });
    return (
      <div style={{ display: "inline-block", verticalAlign: "top" }}>
        {taglist.map((tag) => tag)}
        {this.showAddButton() ? (
          this.props.anaf.topsearchOpen ? (
            this.search()
          ) : (
            this.addButton()
          )
        ) : (
          <span></span>
        )}
      </div>
    );
  }

  addButton() {
    return (
      <div
        title={translate(this.props.anaf.lang, "add_tag")}
        className={classNames("link", "bold", styles.tag, styles.addbutton)}
        onClick={() => this.props.toggleTopSearch(true)}
      >
        <div>{SVGPlus("#333")}</div>
      </div>
    );
  }

  search() {
    return <Topsearch containerClass="topsearch" />;
  }

  showTags() {
    const path = this.props.location.pathname;
    return path == "/explore" || path == "/explore/intro";
  }

  showAddButton() {
    const path = this.props.location.pathname;
    return path != "/explore/intro";
  }

  // {this.Crumb('home', '/home', "",  "", -1)}
  crumbs() {
    return (
      <div className="bold" style={{ display: "inline-block" }}>
        {this.props.anaf.crumbs.map((crumb, index) => this.Crumb(crumb, index))}
      </div>
    );
  }

  Crumb(c: ICrumb, index: number) {
    const crumbStyle = {
      borderColor: "#333",
      backgroundColor: c.bg,
      color: "#333",
    };

    const arrowStyle = {
      strokeWidth: 4,
      height: 15,
      margin: "0px 5px 2px 0px",
    };

    let title = "";
    if (typeof c.title === "string")
      title = translate(this.props.anaf.lang, c.title);
    else title = this.props.anaf.lang == "EN" ? c.title.en : c.title.nl;

    return (
      <Link to={c.link} key={index}>
        <div className={`${styles.crumb} link`} style={crumbStyle}>
          {SVGArrowLeft("#333", arrowStyle)}
          {title.replace("[enter]", " ")}
        </div>
      </Link>
    );
  }

  Tag(tag: ITag, index: number) {
    const crossStyle = {
      height: 11,
      strokeWidth: 3,
      paddingRight: 6,
      verticalAlign: "baseline",
    };

    let title = "";
    if (typeof tag.title === "string") {
      title = tag.title;
      console.log("legacy tag");
    } else
      title = stripMarks(
        this.props.anaf.lang == "EN" ? tag.title.en : tag.title.nl
      );

    return (
      <div className={`${styles.tag} link bold`} key={index}>
        <div onClick={() => this.props.removeTag(tag.type, tag.value)}>
          {SVGCross("#fff", crossStyle)}
          {title}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
