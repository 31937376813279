import * as React from "react";
import {
  SVGArrowRight,
  SVGPlus,
  SVGArrowDown,
  SVGCross,
  SVGArrowLeft,
} from "../../tools/svgTools";
import classNames from "classnames";

import * as styles from "./styles.module.scss";

interface State {}

interface parentProps {
  arrow: string;
  color: string;
  text: string;
  front?: boolean;
  style?: any;
  extraclass?: string[];
}

export class Button extends React.Component<parentProps, State> {
  render() {
    const svgStyle = {
      color: this.props.color,
    };

    if (this.props.front)
      return (
        <div
          style={this.props.style}
          className={classNames(
            styles.leftbutton,
            "link",
            this.props.extraclass
          )}
        >
          <div className={classNames(styles.icon, "bold")} style={svgStyle}>
            {this.icon()} {this.props.text}
          </div>
        </div>
      );

    return (
      <div
        style={this.props.style}
        className={classNames(styles.button, "link", this.props.extraclass)}
      >
        <div className={classNames(styles.icon, "bold")} style={svgStyle}>
          {this.props.text} {this.icon()}
        </div>
      </div>
    );
  }

  icon() {
    switch (this.props.arrow) {
      case "right":
        return SVGArrowRight(this.props.color);
      case "left":
        return SVGArrowLeft(this.props.color);
      case "plus":
        return SVGPlus(this.props.color);
      case "cross":
        return SVGCross(this.props.color);
      case "down":
        return SVGArrowDown(this.props.color);
      default:
        return SVGArrowRight(this.props.color);
    }
  }
}
