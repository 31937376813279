import * as React from "react";
import { connect } from "react-redux";
import { IData } from "../../reducers/dataReducer";
import { withRouter } from "react-router-dom";
import { IAnaf, ITag, ILang } from "../../reducers/anafReducer";
import {
  addTag,
  toggleTopSearch,
  removeAllTags,
  setCloudSearch,
} from "../../actions/anafActions";

import classNames from "classnames";
import { statusTypes } from "../../actions/actionTypes";
import { tagTypes } from "../../actions/actionTypes";
import { translate } from "../../tools/translateTools";
import Autosuggest from "react-autosuggest";

import search from "../../images/AAF_SVG_incons_AAF Search.svg";

import { techniqueList } from "../../tools/tagTools";
import { colourTags } from "../../tools/colorTools";

import { latinize } from "../../tools/translateTools";
import * as styles from "./styles.module.scss";

interface State {
  value: string;
  suggestions: any;
}

interface parentProps {
  history: any;
  containerClass: string;
}

interface StoreProps {
  anaf: IAnaf;
  data: IData;
}

interface DispatchProps {
  addTag: Function;
  toggleTopSearch: Function;
  removeAllTags: Function;
  setCloudSearch: Function;
}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
    data: state.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addTag: (type: tagTypes, value: number, title: ILang) => {
    dispatch(addTag(type, value, title));
  },
  toggleTopSearch: (val: boolean) => {
    dispatch(toggleTopSearch(val));
  },
  removeAllTags: () => {
    dispatch(removeAllTags());
  },
  setCloudSearch: (val: boolean) => {
    dispatch(setCloudSearch(val));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

const getSuggestionValue = (suggestion) => suggestion.name;

const renderSuggestion = (suggestion) => (
  <span className="">{suggestion.name}</span>
);

const YEAR = 0;
const COLOR = 1;
const TECHNIQUE = 2;

export class Topsearch extends React.Component<Props, State> {
  searchinput: any[];

  constructor(props) {
    super(props);

    const lang = this.props.anaf.lang;
    this.searchinput = new Array();

    let year_list = new Array();
    this.props.data.artworks.years.forEach((year, id) => {
      if (!this.inTaglist(tagTypes.YEAR, year))
        year_list.push({
          source: YEAR,
          id: id,
          name: `${year}`,
          published: true,
        });
    });

    this.searchinput.push({
      title: YEAR,
      searchinput: year_list,
    });

    let color_list = new Array();
    colourTags().forEach((color, id) => {
      let name = lang == "EN" ? color.en : color.nl;
      if (!this.inTaglist(tagTypes.COLOUR, id))
        color_list.push({ source: COLOR, id: id, name: name, published: true });
    });

    this.searchinput.push({
      title: COLOR,
      searchinput: color_list,
    });

    let tech_list = new Array();
    techniqueList().forEach((tech, id) => {
      let name = lang == "EN" ? tech.en : tech.nl;
      if (!this.inTaglist(tagTypes.TECHNIQUE, id))
        tech_list.push({
          source: TECHNIQUE,
          id: id,
          name: name,
          published: true,
        });
    });

    this.searchinput.push({
      title: TECHNIQUE,
      searchinput: tech_list,
    });

    this.state = {
      value: "",
      suggestions: [],
    };
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp(latinize(escapedValue), "i");

    const res = this.searchinput
      .map((section) => {
        return {
          title: section.title,
          searchinput: section.searchinput.filter((item) => {
            return item.published && regex.test(latinize(item.name));
          }),
        };
      })
      .filter((section) => section.searchinput.length > 0);

    return res;
  }

  componentDidMount() {}

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder:
        this.props.anaf.lang == "EN"
          ? "Year, Technique, or Color..."
          : "Jaar, Techniek, of Kleur",
      value,
      onChange: this.onChange,
      autoFocus: true,
      onBlur: this.onBlur,
    };

    const searchStyle = {
      height: 27,
      cursor: "pointer",
    };

    // if (!this.props.anaf.topsearchOpen)
    //   return <div onClick={() => this.props.toggleTopSearch()}>
    //   <img src={search} style={searchStyle} />
    // </div>

    return (
      <div className={classNames(this.props.containerClass, styles.topsearch)}>
        <Autosuggest
          multiSection={true}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSectionTitle={(section) => {
            return this.renderSectionTitle(section);
          }}
          getSectionSuggestions={this.getSectionSuggestions}
          onSuggestionSelected={(_, data) => {
            this.onSuggestionSelected(data);
          }}
          inputProps={inputProps}
          alwaysRenderSuggestions={true}
          highlightFirstSuggestion={true}
        />
      </div>
    );
  }

  renderSectionTitle(section) {
    let title = "";
    if (section.title == YEAR) title = translate(this.props.anaf.lang, "year");
    if (section.title == COLOR)
      title = translate(this.props.anaf.lang, "color");
    if (section.title == TECHNIQUE)
      title = translate(this.props.anaf.lang, "technique");

    let titleClass = "section" + section.title;
    return (
      <div className={`bold ${titleClass}`} style={{ paddingLeft: 10 }}>
        {title}
      </div>
    );
  }

  getSectionSuggestions(section) {
    return section.searchinput;
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onBlur = () => {
    this.props.toggleTopSearch(false);
    this.props.setCloudSearch(false);
  };

  onSuggestionSelected(data) {
    this.props.toggleTopSearch(false);
    this.props.setCloudSearch(false);
    this.setState({
      value: "",
    });

    if (data.suggestion.source == YEAR) {
      let year = this.props.data.artworks.years[data.suggestion.id];
      this.props.addTag(tagTypes.YEAR, year, { nl: year, en: year });
    }

    if (data.suggestion.source == COLOR) {
      let color = colourTags()[data.suggestion.id];
      this.props.addTag(tagTypes.COLOUR, data.suggestion.id, color);
    }

    if (data.suggestion.source == TECHNIQUE) {
      let tech = techniqueList()[data.suggestion.id];
      this.props.addTag(tagTypes.TECHNIQUE, data.suggestion.id, tech);
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  haveData() {
    const s = this.props.data.status;
    return s == statusTypes.LOADED || s == statusTypes.UPDATING;
  }

  inTaglist(type: tagTypes, value: number) {
    let found = false;
    this.props.anaf.tags.forEach((tag) => {
      if (tag.type == type && tag.value == value) {
        found = true;
        return;
      }
    });
    return found;
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Topsearch)
);
