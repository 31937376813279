// extracted by mini-css-extract-plugin
var _1 = "yBsnNnNABr1PGT0ck6md";
var _2 = "rwDgyLb0iw6eU_pB9hT8";
var _3 = "EPWLeeYcS5ajpFNDb99f";
var _4 = "g1CqDcwkoLpkuPd6Nwrq";
var _5 = "AC3_0L413c9gQLkTQYBR";
var _6 = "US46StKjPolVoMMA9Uzj";
var _7 = "kqTHYI13D1SRVD1ccKWd";
var _8 = "GPex3a3lWRy1sa7zdCcp";
var _9 = "UyF2en87ue5Ix66_vXsa";
var _a = "ND6F5YjsuZFkIJKVTzkb";
var _b = "ydBb68mhpR32EjvI8pJQ";
var _c = "OkTyb_wNo16Wm_YEmxtl";
var _d = "evkDE3M_895CfHTIRJGb";
var _e = "pgKtCAF3mcOc_H8MJhHr";
var _f = "p0yHDPAFxUHb2k_Z0FiJ";
export { _1 as "arrowleft", _2 as "arrowright", _3 as "contentHolder", _4 as "coverimg", _5 as "imgauthor", _6 as "landingframe", _7 as "legend", _8 as "normaltext", _9 as "quote", _a as "quotePageContent", _b as "quotePageContentHolder", _c as "quotefoot", _d as "rtext", _e as "textcontent", _f as "textright" }
