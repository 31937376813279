import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { layoutTypes } from "../../actions/actionTypes";
import { IAnaf } from "../../reducers/anafReducer";

import { SVGArrowDown } from "../../tools/svgTools";

import * as styles from "./styles.module.css";

interface State {
  stage1: boolean;
  stage2: boolean;
}

interface parentProps {}

interface StoreProps {
  anaf: IAnaf;
}

interface DispatchProps {}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
  };
};

const mapDispatchToProps = (dispatch) => ({});

type Props = StoreProps & DispatchProps & parentProps;

export class Splashpage extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      stage1: false,
      stage2: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.stage1(), 500);
    setTimeout(() => this.stage2(), 1000);
  }

  stage1() {
    this.setState({ stage1: true });
  }

  stage2() {
    this.setState({ stage2: true });
  }

  render() {
    if (this.props.anaf.dataset.splashpage.layout == layoutTypes.VIDEO)
      return this.video();
    else return this.text();
  }

  text() {
    const splashStyle = {
      backgroundImage: "url(" + this.props.anaf.dataset.splashpage.image + ")",
      backgroundSize: "cover",
      width: "100%",
      height: this.props.anaf.height,
    };

    let stage1 = this.state.stage1 ? styles.showconent : "";
    const stage2 = this.state.stage2 ? styles.showconent : "";

    return (
      <div className="d-flex flex-column " style={splashStyle}>
        <div
          className={`${styles.textHolder} ${stage1} bold`}
          style={{ height: "100%" }}
        >
          {this.layout()}
        </div>
        <div className={`${styles.enterHolder} ${stage2} bold`}>
          <Link to="/home">
            <div className={styles.enter}>
              <div className={styles.text}>Enter</div>
              <div className={styles.arrowHolder}>
                {SVGArrowDown("#444", { strokeWidth: 4, height: "1.7rem" })}
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  video() {
    const video = this.props.anaf.dataset.splashpage.content.replace(
      /(<([^>]+)>)/gi,
      ""
    );

    const vidstyle = {
      backgroundColor: this.props.anaf.dataset.splashpage.colour,
    };

    let stage1 = this.state.stage1 ? styles.showconent : "";
    const stage2 = this.state.stage2 ? styles.showconent : "";

    return (
      <div style={vidstyle}>
        <div style={{ height: "100%" }}>
          <div className={styles.video}>
            <iframe
              src={video}
              width="100%"
              height={this.props.anaf.height - 8}
              frameBorder="0"
              allow="autoplay"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className={`${styles.enterHolder} ${stage2} bold`}>
          <Link to="/home">
            <div className={styles.enter}>
              <div className={styles.text}>Enter</div>
              <div className={styles.arrowHolder}>
                {SVGArrowDown("#444", { strokeWidth: 4, height: "1.7rem" })}
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  layout() {
    switch (this.props.anaf.dataset.splashpage.layout) {
      case layoutTypes.TOP_RIGHT:
        return this.top_right();
      case layoutTypes.TOP_LEFT:
        return this.top_left();
      case layoutTypes.BOTTOM_LEFT:
        return this.bottom_left();
      case layoutTypes.BOTTOM_RIGHT:
        return this.bottom_right();
      case layoutTypes.CENTER:
        return this.center();
    }

    return <div>no layout</div>;
  }

  content() {
    const content = (
      <div
        dangerouslySetInnerHTML={{
          __html: this.props.anaf.dataset.splashpage.content,
        }}
      ></div>
    );
    const subcontent = (
      <div
        dangerouslySetInnerHTML={{
          __html: this.props.anaf.dataset.splashpage.subcontent,
        }}
      ></div>
    );

    const contentStyle = {
      color: this.props.anaf.dataset.splashpage.colour,
    };

    return (
      <div style={{ width: "100%" }}>
        <div className="splashpage_content" style={contentStyle}>
          {content}
        </div>
        <div className="splashpage_subcontent" style={contentStyle}>
          {subcontent}
        </div>
      </div>
    );
  }

  top_right() {
    const layoutStyle = {
      width: "50%",
      marginLeft: "auto",
      marginTop: "10%",
    };
    return <div style={layoutStyle}>{this.content()}</div>;
  }

  top_left() {
    const layoutStyle = {
      width: "50%",
      margin: "10% auto 0% 10%",
    };
    return <div style={layoutStyle}>{this.content()}</div>;
  }

  bottom_left() {
    const layoutStyle = {
      height: "100%",
      width: "50%",
      margin: "0% auto 10% 10%",
    };
    return (
      <div className="d-flex align-items-end" style={layoutStyle}>
        {this.content()}
      </div>
    );
  }

  bottom_right() {
    const layoutStyle = {
      height: "100%",
      width: "50%",
      margin: "0% 0% 10% auto",
    };
    return (
      <div className="d-flex align-items-end" style={layoutStyle}>
        {this.content()}
      </div>
    );
  }

  center() {
    const layoutStyle = {
      height: "100%",
      width: "90%",
      margin: "0px auto 0px auto",
      textAlign: "center" as "center",
    };
    return (
      <div className="d-flex align-items-center" style={layoutStyle}>
        {this.content()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Splashpage);
