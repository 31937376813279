import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import { IData } from "../../reducers/dataReducer";
import { IAnaf, ITag, ICrumb } from "../../reducers/anafReducer";
import { resetCrumbs, addCrumbs, removeTag } from "../../actions/anafActions";
import MasonryList from "../masonry/MasonryList";
import { statusTypes } from "../../actions/actionTypes";
import { tagTypes } from "../../actions/actionTypes";
import { stripMarks } from "../pages/ContentPage";
import { scrollTo } from "../../tools/pageTools";
import { shuffle } from "../../tools/generalTools";
import { translate } from "../../tools/translateTools";
import TagCloud from "./TagCloud";
import { ContainerPage } from "../pages/ContentPage";

import * as styles from "./styles.module.css";
import { SVGCross } from "../../tools/svgTools";

interface State {
  randomSet: number[];
}

interface parentProps {
  history: any;
}

interface StoreProps {
  anaf: IAnaf;
  data: IData;
}

interface DispatchProps {
  resetCrumbs: Function;
  addCrumbs: Function;
  removeTag: Function;
}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
    data: state.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetCrumbs: () => {
    dispatch(resetCrumbs());
  },
  addCrumbs: (crumb: ICrumb[]) => {
    dispatch(addCrumbs(crumb));
  },
  removeTag: (type: tagTypes, value: number) => {
    dispatch(removeTag(type, value));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

export class Explore extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      randomSet: [],
    };
  }

  componentDidMount() {
    this.props.resetCrumbs();
    let home: ICrumb = { title: "home", link: "/home", bg: "#fff" };
    let explore: ICrumb = {
      title: "explore",
      link: "/explore/intro",
      bg: "#8bffec",
    };

    this.props.addCrumbs([home, explore]);
  }

  render() {
    const lang = this.props.anaf.lang;
    const tit = translate(lang, "explore");

    if (!this.haveData()) return <div>Loading...</div>;

    let result = this.searchAnd();

    if (this.props.anaf.tags.length == 0) return this.noSelection();

    if (result.length == 0) return this.noResults();

    return (
      <div style={{ marginTop: "130px" }}>
        <Helmet>
          <title>AkzoNobel Art Foundation | {tit} </title>
          <meta
            name="description"
            content={`AkzoNobel Art Foundation | ${tit}`}
          />
        </Helmet>

        <MasonryList artwork_ids={result} />
      </div>
    );
  }

  searchAnd() {
    let ids = this.props.data.artworks.byIds;
    let result = new Array();

    Object.keys(ids).map((id) => {
      let artwork = this.props.data.artworks.byIds[id];
      var found = 0;
      let tagcount = this.props.anaf.tags.length;

      for (let i = 0, len = tagcount; i < len; i++) {
        let tag = this.props.anaf.tags[i];

        switch (tag.type) {
          case tagTypes.TECHNIQUE:
            {
              if (artwork.technique_id == tag.value) {
                found += 1;
              }
            }
            break;

          case tagTypes.YEAR:
            if (artwork.year == tag.value) {
              found += 1;
            }
            break;

          case tagTypes.ARTWORK:
            if (artwork.id == tag.value) {
              found += 1;
            }
            break;

          case tagTypes.COLOUR:
            if ((artwork.ctags & Math.pow(2, tag.value)) > 0) {
              found += 1;
            }
            break;

          case tagTypes.THEME:
            if (artwork.exhibitions.includes(tag.value)) found += 1;
            break;
        }
      }

      if (found == tagcount) result.push(artwork.id);
    });

    return result;
  }

  searchOr() {
    let ids = this.props.data.artworks.byIds;
    let result = new Array();

    Object.keys(ids).map((id) => {
      let artwork = this.props.data.artworks.byIds[id];
      var found = false;

      for (let i = 0, len = this.props.anaf.tags.length; i < len; i++) {
        let tag = this.props.anaf.tags[i];

        switch (tag.type) {
          case tagTypes.TECHNIQUE:
            {
              if (artwork.technique_id == tag.value) {
                found = true;
              }
            }
            break;

          case tagTypes.YEAR:
            if (artwork.year == tag.value) {
              found = true;
            }
            break;

          case tagTypes.ARTWORK:
            if (artwork.id == tag.value) {
              found = true;
            }
            break;

          case tagTypes.COLOUR:
            if ((artwork.ctags & Math.pow(2, tag.value)) > 0) {
              found = true;
            }
            break;
        }
      }

      if (found) result.push(artwork.id);
    });

    return result;
  }

  noSelection() {
    this.props.history.push("/explore/intro#select");

    return (
      <div>
        <ContainerPage bgColour="#8bffec" pageId="p2">
          <h2>{translate(this.props.anaf.lang, "select_tag")}</h2>
          <br />
          <TagCloud />
        </ContainerPage>
      </div>
    );
  }

  noResults() {
    return (
      <div>
        <ContainerPage bgColour="#8bffec" pageId="p2">
          <h2>{translate(this.props.anaf.lang, "remove_tag")}</h2>
          {this.currentTags()}
        </ContainerPage>
      </div>
    );
    // <MasonryList artwork_ids={this.randomSet()}/>
  }

  currentTags() {
    return <div>{this.props.anaf.tags.map((tag: ITag) => this.tag(tag))}</div>;
  }

  tag(tag) {
    return (
      <div
        className={`${styles.tag} link bold`}
        onClick={() => {
          this.props.removeTag(tag.type, tag.value);
        }}
      >
        <div>
          <div className={styles.icon}>{SVGCross("#8bffec")}</div>{" "}
          {stripMarks(
            this.props.anaf.lang == "EN" ? tag.title.en : tag.title.nl
          )}
        </div>
      </div>
    );
  }

  randomSet() {
    if (this.state.randomSet.length == 0) this.populateRandomSet();
    return this.state.randomSet;
  }

  populateRandomSet() {
    let ids = this.props.data.artworks.byIds;
    let result = new Array();

    Object.keys(ids).map((id) => {
      let artwork = this.props.data.artworks.byIds[id];
      result.push(artwork.id);
    });
    this.setState({
      randomSet: shuffle(result),
    });
  }

  haveData() {
    const s = this.props.data.status;
    return s == statusTypes.LOADED || s == statusTypes.UPDATING;
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Explore)
);
