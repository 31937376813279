import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import { IAnaf, ICrumb } from "../../reducers/anafReducer";
import { resetCrumbs, addCrumbs } from "../../actions/anafActions";

import { Button } from "../buttons/Button";

import { translate } from "../../tools/translateTools";

import Map from "./Map";
import Contactform from "./Contactform";

import * as styles from "./styles.module.css";

interface State {
  path: string;
}

interface parentProps {
  history: any;
  location: any;
}

interface StoreProps {
  anaf: IAnaf;
}

interface DispatchProps {
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetCrumbs: () => {
    dispatch(resetCrumbs());
  },
  addCrumbs: (crumb: ICrumb[]) => {
    dispatch(addCrumbs(crumb));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

export class Contact extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      path: "",
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.location.pathname != state.path) {
      const title = "Contact + Route";
      props.resetCrumbs();
      let home: ICrumb = { title: "home", link: "/home", bg: "#b4d2e6" };
      let contact: ICrumb = {
        title: { en: title, nl: title },
        link: "/contact",
        bg: "#b4d2e6",
      };
      props.addCrumbs([home, contact]);
      return {
        path: props.location.pathname,
      };
    }

    return null;
  }

  //{translate(this.props.anaf.lang, 'corona')}
  //            <br />
  //            <br />
  render() {
    return (
      <div className={`row mr-0 rl-0 ${styles.contactwindow} `}>
        <Helmet>
          <title>AkzoNobel Art Foundation | Contact + Route</title>
          <meta
            name="description"
            content={`AkzoNobel Art Foundation | Contact + Route`}
          />
        </Helmet>

        <div className={`col-12 col-md-6 ${styles.contactform}`}>
          <Contactform />
        </div>
        <div
          className={`col-12 col-md-6 ${styles.subwindow} ${styles.routewindow}`}
        >
          <h1 className={`bold ${styles.header}`}>Route</h1>

          <div className={`row bold ${styles.subheader}`}>
            {this.contact_text()}
          </div>
          <div className={`row bold ${styles.subheader}`}>
            <div className="col-6">
              AkzoNobel Center
              <br />
              Christian Neefestraat 2<br />
              1077 WW Amsterdam
              <br />
            </div>
            <div className="col-6">
              {translate(this.props.anaf.lang, "monday_friday")}
              <br />
              {translate(this.props.anaf.lang, "opening_times")}
              <br />
              {translate(this.props.anaf.lang, "free_entrance")}
            </div>
          </div>
          <Map />
          <a
            href="https://www.google.com/maps/dir//AkzoNobel+Center,+Christian+Neefestraat+2,+1077+WW+Amsterdam/@52.3398678,4.8421129,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c60a041133379b:0xff5b56ba99fa2c1b!2m2!1d4.877218!2d52.339873!3e0"
            target="_blank"
          >
            <Button
              arrow="right"
              style={{ float: "right" }}
              color="#b4d2e6"
              text="Route"
            />
          </a>
        </div>
      </div>
    );
  }

  contact_text() {
    const anaf = this.props.anaf;
    let content =
      anaf.lang == "EN"
        ? anaf.dataset.contact.content_en
        : anaf.dataset.contact.content_nl;
    return (
      <div className="col-12">
        <div
          className={styles.contacttext}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contact)
);
