// extracted by mini-css-extract-plugin
var _1 = "kdpH7h6FQmY_zEsSzqD9";
var _2 = "wXOhLsCpc28cSfMbtU7j";
var _3 = "CiV86wvIOrW4lfRkZINy";
var _4 = "bfHnhsxPvDQbtHJmw_Rx";
var _5 = "G5dVA4QmIxu_WgqMQwks";
var _6 = "Baz2MneJEdVDIGCAxPj1";
var _7 = "oWj6jqqejlefUmlH6dA9";
var _8 = "dg_2RcuCp3ud0XzIxrNG";
var _9 = "e_gqTBaBwh0aKG5HwgSb";
var _a = "Fi9YQehkEFczzw0VtfGu";
var _b = "lqpmd9TZlMHu84sNrqBg";
var _c = "xTyOiT1tkrhfSxQwelCz";
var _d = "C8R2nVjeLbGn8qdJ_9YA";
export { _1 as "address", _2 as "border", _3 as "borderholder", _4 as "button", _5 as "content", _6 as "crossholder", _7 as "imageHolder", _8 as "infobutton", _9 as "mainTitle", _a as "moment", _b as "page", _c as "rotate", _d as "title" }
