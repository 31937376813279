import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import classNames from "classnames";

import { IAnaf } from "../../reducers/anafReducer";
import { statusTypes } from "../../actions/actionTypes";
import Breadcrumbs from "./Breadcrumbs";

import { translate } from "../../tools/translateTools";
import { onTourPage } from "../../tools/pageTools";

import logoFull from "../../images/aaf_logo_topbar.svg";
import logoSingle from "../../images/AAF_SVG_incons_AAF A.svg";

import {
  SVGCross,
  SVGPlus,
  SVGHamburger,
  SVGArrowRight,
} from "../../tools/svgTools";

import * as styles from "./styles.module.scss";

interface State {
  showMenu: boolean;
  firstTime: boolean;
  subMenuIndex: number;
  timer: number;
}

interface parentProps {
  location: any;
  match: any;
}

interface StoreProps {
  anaf: IAnaf;
}

interface DispatchProps {}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
  };
};

const mapDispatchToProps = (dispatch) => ({});

type Props = StoreProps & DispatchProps & parentProps;

const logoDiv = (
  <div>
    <Link to="/home">
      <img src={logoFull} className={styles.logoFull} />
      <img src={logoSingle} className={styles.logoSingle} />
    </Link>
  </div>
);

export class Header extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      subMenuIndex: -1,
      firstTime: true,
      timer: -1,
    };
  }

  render() {
    if (this.onHomePage() || !this.haveData()) return <span></span>;

    if (onTourPage(this.props.location.pathname)) return this.tourHeader();

    const open = this.state.showMenu;
    const openedClass = open
      ? styles.open
      : this.state.firstTime
      ? ""
      : styles.closed;

    //

    return (
      <>
        <div
          className={`${styles.header} ${openedClass}`}
          onMouseLeave={() => this.checkTimer()}
        >
          <div className="d-flex">
            {this.menuToggler()}
            {logoDiv}
          </div>
          <div className={styles.barholder}>
            <div className={styles.bar}></div>
          </div>
          {this.menus()}
        </div>
        {<Breadcrumbs location={this.props.location} />}
      </>
    );
  }

  tourHeader() {
    return (
      <>
        <div className={styles.header} id="tourheader">
          <div className="d-flex flex-md-row-reverse ">{this.tourLogo()}</div>
          <div className={styles.barholder}>
            <div className={styles.bar}></div>
          </div>
        </div>
      </>
    );
  }

  tourLogo() {
    return (
      <div>
        <img src={logoFull} className={styles.logoTour} />
      </div>
    );
  }

  onHomePage() {
    return (
      this.props.location.pathname == "/" ||
      this.props.location.pathname == "/home"
    );
  }

  menuToggler() {
    return (
      <div
        onClick={() =>
          this.state.showMenu ? this.hideMenu() : this.showMenu()
        }
        className={classNames(
          "flex-grow-1",
          "link",
          styles.toggleButton,
          { closed: !this.state.showMenu && !this.state.firstTime },
          { opened: this.state.showMenu }
        )}
      >
        {SVGHamburger("#333")}
      </div>
    );
  }

  SubmenuItems(props) {
    if (props.current != props.index) return <div className="col"></div>;
    return (
      <div className={`${styles.submenu_holder} col`}>{props.children}</div>
    );
  }

  SubmenuItem(to: string, title: string) {
    return (
      <Link to={to}>
        <div
          className={`bold ${styles.subitem}`}
          key={to}
          onClick={() => this.hideMenu()}
        >
          {title}
          <div className={styles.rightarrow}>
            {SVGArrowRight("#333", { strokeWidth: 2, width: 30 })}
          </div>
        </div>
      </Link>
    );
  }

  menus() {
    let lang = this.props.anaf.lang;
    return (
      <div
        className={classNames(styles.topmenu, "row", {
          [`${styles.topopen}`]: this.state.showMenu,
        })}
      >
        {this.menuItem(translate(lang, "visit_us"), 0)}
        {this.menuItem(translate(lang, "explore_short"), 1)}
        {this.menuItem(translate(lang, "about"), 2)}
        {this.menuItem(translate(lang, "collaborate"), 3)}
      </div>
    );
  }

  menuItem(title: string, index: number) {
    const subs = this.props.anaf.dataset.menus;
    const lang = this.props.anaf.lang;
    const showPlus = this.state.subMenuIndex == index;

    return (
      <div key={title} className={`${styles.menu_holder} col-md-3 col-sm-12`}>
        <div
          className={`${styles.menuitem} bold link`}
          onClick={() => this.showSubMenu(index)}
        >
          {title}
          <div
            className={classNames(styles.plus, {
              [`${styles.hidePlus}`]: showPlus,
            })}
          >
            {SVGPlus("#333", { strokeWidth: 2, width: 24 })}
          </div>
        </div>
        <this.SubmenuItems index={index} current={this.state.subMenuIndex}>
          {subs[index].map((item) =>
            this.SubmenuItem(
              item.stub,
              lang == "EN" ? item.title_en : item.title_nl
            )
          )}
        </this.SubmenuItems>
      </div>
    );
  }

  checkTimer() {
    const delta = 1000;
    let now = new Date();
    // if ( now.getTime() - this.state.timer > delta )
    //   this.hideMenu();
  }

  hideMenu() {
    this.setState({
      showMenu: false,
      subMenuIndex: -1,
    });
  }

  showMenu() {
    let t = new Date();

    this.setState({
      showMenu: true,
      firstTime: false,
      timer: t.getTime(),
    });
  }

  showSubMenu(index: number) {
    const menu = this.state.subMenuIndex == index ? -1 : index;
    this.setState({
      subMenuIndex: menu,
    });
  }

  haveData() {
    const s = this.props.anaf.dataset.status;
    return s == statusTypes.LOADED || s == statusTypes.UPDATING;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
