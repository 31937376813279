import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { resetCrumbs, addCrumbs } from "../../actions/anafActions";
import { statusTypes } from "../../actions/actionTypes";
import { IPages, IPage } from "../../reducers/pageReducer";
import { loadPages } from "../../actions/artworkActions";
import { ICrumb, ILang } from "../../reducers/anafReducer";
import { Button } from "../buttons/Button";
import { Input } from "reactstrap";

import axios, { AxiosRequestConfig, AxiosPromise } from "axios";

import { lightColorlist } from "../../tools/colorTools";
import { withRouter } from "react-router-dom";
import { translate } from "../../tools/translateTools";
import { Helmet } from "react-helmet";
import classNames from "classnames";

import * as styles from "./styles.module.scss";

enum FORMSTATE {
  Init,
  Failed,
  Success,
}

interface PressEvent {
  id: number;
  title_nl: string;
  title_en: string;
  subtitle_nl: string;
  subtitle_en: string;
  description_en: string;
  description_nl: string;
  pressinfo: string;
}

interface State {
  events: PressEvent[];
  path: string;
  code: string;
  page: IPage;
  formstate: FORMSTATE;
}

interface parentProps {
  history: any;
  match: any;
  location: any;
}

interface StoreProps {
  pages: IPages;
  lang: string;
}

interface DispatchProps {
  loadPages: Function;
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = (state) => {
  return {
    pages: state.pages,
    lang: state.anaf.lang,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadPages: () => {
    dispatch(loadPages());
  },
  resetCrumbs: () => {
    dispatch(resetCrumbs());
  },
  addCrumbs: (crumb: ICrumb[]) => {
    dispatch(addCrumbs(crumb));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

export class Page extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      events: null,
      path: "",
      code: "",
      page: null,
      formstate: FORMSTATE.Init,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.location.pathname != state.path) {
      if (props.pages.status > statusTypes.LOADING) {
        const slugs = props.pages.pages.bySlugs;
        const page = slugs[props.match.params.slug];

        let title: ILang = { en: "Not found", nl: "Niet gevonden" };
        let template = "default";

        const cl = lightColorlist();
        let color = cl[0];

        if (page !== undefined) {
          title = { en: page.title_en, nl: page.title_nl };
          color = cl[page.color];
          template = page.template;
        }

        props.resetCrumbs();

        if (template == "press") color = "#b2d0e8";

        let home: ICrumb = { title: "home", link: "/home", bg: color };
        let p: ICrumb = {
          title: title,
          link: props.match.params.slug,
          bg: color,
        };

        if (template == "default" || template == "press")
          props.addCrumbs([home, p]);

        return {
          path: props.location.pathname,
        };
      }
    }

    return null;
  }

  componentDidMount() {
    if (this.props.pages.status == statusTypes.INIT) this.props.loadPages();
  }

  render() {
    if (!this.haveData()) return <div> loading </div>;

    const slugs = this.props.pages.pages.bySlugs;
    const page = slugs[this.props.match.params.slug];

    if (page === undefined) {
      return (
        <div>
          <h1 className={styles.header}>Page not found</h1>
        </div>
      );
    }

    const title = this.props.lang == "EN" ? page.title_en : page.title_nl;
    const content = this.props.lang == "EN" ? page.content_en : page.content_nl;

    const pageStyle = {
      backgroundColor: lightColorlist()[page.color],
      minHeight: "100vh",
    };

    switch (page.template) {
      case "wac":
        pageStyle.backgroundColor = "#fff";
        return this.wac(page, title, content, pageStyle);
      case "press":
        pageStyle.backgroundColor = "#b2d0e8";
        return this.press_landing(page, title, content, pageStyle);
    }

    return this.default(page, title, content, pageStyle);
  }

  default(page, title, content, pageStyle) {
    return (
      <div style={pageStyle}>
        <Helmet>
          <title>AkzoNobel Art Foundation | {title} </title>
          <meta
            name="description"
            content={`AkzoNobel Art Foundation | ${title}`}
          />
        </Helmet>
        <div>
          <h1 className={styles.header}>{title}</h1>
        </div>
        <div
          className={`bold ${styles.content}`}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    );
  }

  press_landing(page, title, content, pageStyle) {
    return (
      <div style={pageStyle}>
        <Helmet>
          <title>AkzoNobel Art Foundation | {title} </title>
          <meta
            name="description"
            content={`AkzoNobel Art Foundation | ${title}`}
          />
        </Helmet>

        <div className={styles.halfhalf}>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className={classNames(styles.halfholder)}>
                <h1 className={classNames(styles.header, styles.header_half)}>
                  {title}
                </h1>
                <div
                  className={classNames(styles.pressgeneral, "bold")}
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
              </div>
            </div>

            <div className={classNames(styles.halfside, "col-12 col-md-6")}>
              {this.state.events ? this.events_page() : this.login_form()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  events_page() {
    return (
      <div className={classNames(styles.halfholder)}>
        {this.state.events.map((event) => this.eventItem(event))}
      </div>
    );
  }

  eventItem(event) {
    const title = this.props.lang == "EN" ? event.title_en : event.title_nl;
    const subtitle =
      this.props.lang == "EN" ? event.subtitle_en : event.subtitle_nl;
    const content =
      this.props.lang == "EN" ? event.description_en : event.description_nl;

    return (
      <div className={styles.event_holder} key={event.id}>
        <h1 className={classNames(styles.header, styles.header_half)}>
          {title}
        </h1>
        <h2 className={classNames(styles.event_subtitle, "bold")}>
          {subtitle}
        </h2>
        <div
          className={classNames("")}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
        {this.download(event)}
      </div>
    );
  }

  download(event) {
    //"ALL_EYES_Presskit_Persmap.zip"

    let file = event.pressinfo;
    if (event.id == 2)
      file =
        "https://artfoundation.akzonobel.com/assets/ALL_EYES_Presskit_Persmap.zip";

    return (
      <div>
        <a href={file}>
          <Button arrow="right" color="#b2d0e8" text={"Download"} />
        </a>
      </div>
    );
  }

  coded_page() {
    const page = this.state.page;
    const title = this.props.lang == "EN" ? page.title_en : page.title_nl;
    const content = this.props.lang == "EN" ? page.content_en : page.content_nl;

    return (
      <div className={classNames(styles.halfholder)}>
        <div>
          <h1 className={classNames(styles.header, styles.header_half)}>
            {title}
          </h1>
        </div>
        <div
          className={classNames("bold")}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    );
  }

  login_form() {
    return (
      <div className={classNames(styles.halfholder)}>
        <div>
          <h1 className={classNames(styles.header, styles.header_half)}>
            Login
          </h1>
        </div>
        <div>
          <Input
            placeholder="Code"
            // invalid={this.state.code.trim().length == 0}
            type="text"
            name="code"
            value={this.state.code}
            className={styles.input}
            onChange={(e) => this.handleInputChange(e)}
          />
        </div>

        <div className="link" onClick={() => this.submit()}>
          <Button
            style={{ float: "right", backgroundColor: "#000" }}
            arrow="right"
            text="Login"
            color="#b2d0e8"
          />
        </div>

        {this.state.formstate == FORMSTATE.Failed ? (
          <div>{translate(this.props.lang, "code_error")}</div>
        ) : (
          <span></span>
        )}
      </div>
    );
  }

  handleInputChange(event) {
    this.setState({
      code: event.target.value,
    });
  }

  submit() {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    if (this.state.code.trim().length == 0) return;

    let that = this;

    axios
      .post(
        "/code.json",
        {
          code: this.state.code,
        },
        {
          headers: {
            "X-CSRF-Token": csrfToken,
          },
        }
      )
      .then(function(result) {
        console.log(result);
        console.log("worked");
        if (result.data.msg != "FAIL") {
          that.setState({
            events: result.data,
            formstate: FORMSTATE.Success,
          });
        } else {
          that.setState({
            formstate: FORMSTATE.Failed,
          });
        }
      })
      .catch(function(error) {
        if (error.response) {
          console.log("Error", error.message);
        }
      });
  }

  wac(page, title, content, pageStyle) {
    const inner = window.innerWidth;
    let h = 0;
    if (inner > 768) h = inner * 0.6 * 0.55;
    else h = window.innerWidth * 0.55;

    const source = "https://player.vimeo.com/video/458602544";

    content = content.replace(
      /\[img\](.*)\[\/img\]/g,
      '<img class="img-fluid" src="/$1" />'
    );

    return (
      <div style={pageStyle}>
        <Helmet>
          <title>AkzoNobel Art Foundation | {title} </title>
          <meta
            name="description"
            content={`AkzoNobel Art Foundation | ${title}`}
          />
        </Helmet>
        <div>
          <h1 className={classNames(styles.header, styles.header_wac)}>
            {title}
          </h1>
        </div>
        <div className={styles.contain_width}>
          <div className={styles.video}>
            <img src="/cover2.jpg" className="img-fluid" />
          </div>

          <div
            className={classNames("bold", styles.content_white)}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>
    );
  }

  // <div className={styles.video}>
  //   <iframe
  //     src={source}
  //     width="100%"
  //     height={h}
  //     frameBorder="0"
  //     allow="autoplay; fullscreen"
  //     allowFullScreen>
  //   </iframe>
  // </div>

  haveData() {
    const s = this.props.pages.status;
    return s == statusTypes.LOADED || s == statusTypes.UPDATING;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
