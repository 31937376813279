// extracted by mini-css-extract-plugin
var _1 = "uJnjel7s_C68Kdk2ox9Z";
var _2 = "McXXfc6BU3XFYHNoQP7M";
var _3 = "oB8Ms51zUUJgS6qYXaKH";
var _4 = "pHoMWvsp_QsoLJnyw_IA";
var _5 = "BZOQZZ_39XUuvqoHX7_Y";
var _6 = "mjy0GyGwF1n8q3KBQIM2";
var _7 = "Iht32l1A8x94r_ZFkGLE";
var _8 = "EFd4MbHL679omaxZB3Vq";
var _9 = "UL9Yl0h1c99Iy0KLggqA";
var _a = "UObbET23gWFnvtcUFzLo";
var _b = "QEVnQIov5qMpNxg62QEw";
var _c = "BHHEEW4dimBEVTa3okSw";
var _d = "vGh3yGiLejaGHb_P7Hig";
export { _1 as "address", _2 as "border", _3 as "borderholder", _4 as "button", _5 as "content", _6 as "crossholder", _7 as "imageHolder", _8 as "infobutton", _9 as "mainTitle", _a as "moment", _b as "page", _c as "rotate", _d as "title" }
