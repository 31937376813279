import * as React from "react";
import classNames from "classnames";

import { ScrollArrow, ScrollHolder } from "./Scrolling";
import * as styles from "./styles.module.css";

interface State {}

interface parentProps {
  textColour?: string;
  content?: any;
  pageId: string;
  next?: string;
  prev?: string;
  bgColour?: string;
  bottom?: any;
  extraWide?: boolean;
  extraClasses?: string[];
}

export class ContentPage extends React.Component<parentProps, State> {
  render() {
    return (
      <ScrollHolder className={styles.window} id={this.props.pageId}>
        <div className={`${styles.top}`}>
          <ScrollArrow target={this.props.prev} direction="up" />
        </div>
        <div
          className={`${styles.contentHolder} ${styles.contentPageContentHolder} d-flex align-items-center`}
        >
          <div
            className={classNames(
              styles.contentPageContent,
              this.props.extraClasses
            )}
            style={{ color: this.props.textColour }}
          >
            {this.props.content}
          </div>
        </div>
        <ScrollArrow target={this.props.next} direction="down" />
      </ScrollHolder>
    );
  }
}

export class QuotePage extends React.Component<parentProps, State> {
  render() {
    return (
      <ScrollHolder className={styles.window} id={this.props.pageId}>
        <div className={`${styles.top}`}>
          <ScrollArrow target={this.props.prev} direction="up" />
        </div>
        <div
          className={`${styles.contentHolder} ${styles.quotePageContentHolder} d-flex align-items-center bold`}
        >
          <div
            className={styles.quotePageContent}
            style={{ color: this.props.textColour }}
          >
            {this.props.content}
          </div>
        </div>
        <ScrollArrow target={this.props.next} direction="down" />
      </ScrollHolder>
    );
  }
}

export class ContainerPage extends React.Component<parentProps, State> {
  render() {
    return (
      <ScrollHolder
        className={styles.window}
        id={this.props.pageId}
        style={{ backgroundColor: this.props.bgColour }}
      >
        <div className={`${styles.top}`}>
          <ScrollArrow target={this.props.prev} direction="up" />
        </div>
        <div
          className={classNames(
            styles.contentHolder,
            styles.quotePageContentHolder,
            "d-flex",
            "align-items-center",
            "bold",
            { [`${styles.wideContent}`]: this.props.extraWide }
          )}
        >
          <div
            className={styles.quotePageContent}
            style={{ color: this.props.textColour, width: "100%" }}
          >
            {this.props.children}
          </div>
        </div>
        {this.props.bottom ? (
          this.props.bottom
        ) : (
          <ScrollArrow target={this.props.next} direction="down" />
        )}
      </ScrollHolder>
    );
  }
}

export class SimpleContainerPage extends React.Component<parentProps, State> {
  render() {
    return (
      <ScrollHolder
        className={styles.window}
        id={this.props.pageId}
        style={{ backgroundColor: this.props.bgColour }}
      >
        <div className={`${styles.top}`}>
          <ScrollArrow target={this.props.prev} direction="up" />
        </div>
        <div
          ref="child"
          className={`${styles.contentHolder} d-flex flex-column`}
        >
          {this.props.children}
        </div>
        {this.props.bottom ? (
          this.props.bottom
        ) : (
          <ScrollArrow target={this.props.next} direction="down" />
        )}
      </ScrollHolder>
    );
  }
}

export function parseContent(content) {
  let c = new Array();
  let res = content.replace("[enter]", "<br />");
  let parts = res.split("[pagebreak]");

  return parts;
}

export function stripMarks(content) {
  return content.toString().replace("[enter]", " ");
}
