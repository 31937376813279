import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { IAnaf } from "../../reducers/anafReducer";
import { statusTypes } from "../../actions/actionTypes";

import { shuffle } from "../../tools/generalTools";
import { colorlist } from "../../tools/colorTools";

import arrow_lt from "../../images/AAF_SVG_incons_AAF Arrow -45.svg";
import arrow_r from "../../images/AAF_SVG_incons_AAF Arrow 90.svg";
import arrow_rb from "../../images/AAF_SVG_incons_AAF Arrow 135.svg";
import arrow_rt from "../../images/AAF_SVG_incons_AAF Arrow 45.svg";

import logoFull from "../../images/an_logo_full.svg";

import about_us_en from "../../images/AKZ_About_us.svg";
import about_us_nl from "../../images/AKZ_Over_ons.svg";

import std from "../../images/savethedate2.svg";
import newsite from "../../images/newwebsite.svg";
import soon from "../../images/comingsoon.svg";
import watc from "../../images/watc.svg";

import highlights_en from "../../images/highlights_section.svg";
import highlights_nl from "../../images/highlights_rubriek.svg";

import * as styles from "./styles.module.css";

interface State {
  stage: number;
}

interface parentProps {
  history: any;
}

interface StoreProps {
  anaf: IAnaf;
}

interface DispatchProps {}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
  };
};

const mapDispatchToProps = (dispatch) => ({});

type Props = StoreProps & DispatchProps & parentProps;

const ArrowStyle = {
  width: "3.5vw",
};

const TIMER = 500;
const STAGES = 6;

export class Home extends React.Component<Props, State> {
  colors: any;
  loaded: boolean;

  constructor(props) {
    super(props);

    this.loaded = false;
    this.colors = colorlist();

    this.state = {
      stage: 0,
    };
  }

  componentDidMount() {
    setTimeout(() => this.updateStage(), TIMER);
  }

  updateStage() {
    if (this.state.stage < STAGES) {
      setTimeout(() => this.updateStage(), TIMER);
    }

    this.setState({
      stage: this.state.stage + 1,
    });
  }

  // setColors() {
  //   if (!this.loaded) {
  //     let tour_colour = this.props.anaf.dataset.homepage.tour_colour
  //     this.colors.splice(tour_colour, 1)
  //     this.colors = shuffle(this.colors)
  //     this.loaded = true
  //   }
  // }

  render() {
    // if (this.haveData())
    // this.setColors()

    const homepage = this.props.anaf.dataset.homepage;
    const c = this.colors[7];

    const stdStyle = {
      backgroundColor: `rgb(${c.r},${c.g},${c.b})`,
      backgroundImage: `url("${this.floatimg()}")`,
    };

    const lang = this.props.anaf.lang;
    const text1 = lang == "NL" ? homepage.text1_nl : homepage.text1_en;
    const text2 = lang == "NL" ? homepage.text2_nl : homepage.text2_en;
    const about = lang == "NL" ? about_us_nl : about_us_en;

    const visibleLogo = this.state.stage > 0 ? styles.showcontent : "";
    const visibleSTD = this.state.stage > 5 ? styles.showcontent : "";
    const visibleText = this.state.stage > 6 ? styles.showcontent : "";

    return (
      <div className="row no-gutters">
        <div className="col-md-6 col-sm-12">
          {this.leftside(homepage, text1)}
          <div className={`${styles.colab} ${visibleText} bold`}>
            <Link to={homepage.link3} style={{ paddingRight: 20 }}>
              <img src={about} className={styles.about} />
              <img src={arrow_r} style={ArrowStyle} />
            </Link>
          </div>
          <Link to={homepage.link4}>
            <div
              className={`${styles.std} ${visibleSTD}`}
              style={stdStyle}
            ></div>
          </Link>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className={`${styles.logoHolder} ${visibleLogo}`}>
            <img src={logoFull} className={styles.logoFull} />
          </div>
          {this.rightside(homepage, text2)}
        </div>
      </div>
    );
  }

  floatimg() {
    switch (this.props.anaf.dataset.homepage.image_id) {
      case 0:
        return std;
        break;

      case 1:
        return newsite;
        break;

      case 2:
        return soon;
        break;

      case 3:
        return this.props.anaf.lang == "EN" ? highlights_en : highlights_nl;
        break;

      case 4:
        return watc;

      default:
        return std;
        break;
    }
  }

  leftside(homepage, text) {
    const c = colorlist()[homepage.tour_colour];
    const leftDiv = {
      backgroundColor: `rgb(${c.r},${c.g},${c.b})`,
    };

    const visible = this.state.stage > 1 ? styles.leftSideShow : "";
    const visibleText = this.state.stage > 4 ? styles.showcontent : "";

    return (
      <Link to={homepage.link1}>
        <div
          style={leftDiv}
          className={`${styles.leftSide} ${visible} d-flex align-items-start flex-column`}
        >
          <div className="mb-auto"></div>
          <div className={`${styles.leftContentHolder} ${visibleText}`}>
            <h1 className={`${styles.bigText} ${styles.leftSideH1} bold`}>
              {text}
              <img src={arrow_rb} className={styles.leftSideArrow} />
            </h1>
            <img src={arrow_rb} className={styles.leftSideArrowSmall} />
          </div>
        </div>
      </Link>
    );
  }

  rightside(homepage, text) {
    const c = this.colors[6];

    const rightDiv = {
      backgroundColor: `rgb(${c.r},${c.g},${c.b})`,
    };
    const visible = this.state.stage > 1 ? styles.rightSideShow : "";
    const visibleText = this.state.stage > 4 ? styles.showcontent : "";

    return (
      <Link to={homepage.link2}>
        <div
          style={rightDiv}
          className={`${styles.rightSide} ${visible} d-flex align-items-start flex-column`}
        >
          <div
            className={`mb-auto ${styles.rightSideArrowHolder} ${visibleText}`}
          >
            <img src={arrow_lt} className={styles.rightSideArrow} />
            <img src={arrow_rt} className={styles.rightSideArrowSmall} />
          </div>
          <div>
            <h1
              className={`${styles.bigText} ${styles.rightSideH1} ${visibleText} bold`}
            >
              {text}
            </h1>
          </div>
        </div>
      </Link>
    );
  }

  haveData() {
    const s = this.props.anaf.dataset.status;
    return s == statusTypes.LOADED || s == statusTypes.UPDATING;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
