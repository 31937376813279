import * as React from "react";
import { connect } from "react-redux";

import { Helmet } from "react-helmet";
import classNames from "classnames";

import { IData } from "../../reducers/dataReducer";
import { IAnaf, ICrumb } from "../../reducers/anafReducer";
import { translate } from "../../tools/translateTools";
import { statusTypes } from "../../actions/actionTypes";
import { resetCrumbs, addCrumbs } from "../../actions/anafActions";

import Searchbox from "./Searchbox";

import * as styles from "./search.module.scss";

interface State {
  test: boolean;
  advanced: boolean;
}

interface parentProps {
  match: any;
  location: any;
}

interface StoreProps {
  anaf: IAnaf;
  data: IData;
}

interface DispatchProps {
  loadEvents: Function;
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
    data: state.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetCrumbs: () => {
    dispatch(resetCrumbs());
  },
  addCrumbs: (crumb: ICrumb[]) => {
    dispatch(addCrumbs(crumb));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

export class Search extends React.Component<Props, State> {
  componentDidMount() {
    this.props.resetCrumbs();

    let home: ICrumb = { title: "home", link: "/home", bg: "#8bffec" };
    let explore: ICrumb = {
      title: "explore",
      link: "/explore/intro",
      bg: "#8bffec",
    };
    let events: ICrumb = { title: "search", link: "/search", bg: "#8bffec" };

    this.props.addCrumbs([home, explore, events]);
  }

  constructor(props) {
    super(props);

    this.state = {
      test: props.location.search == "?jaar=1",
      advanced: props.location.search == "?advanced",
    };
  }

  render() {
    const title = translate(this.props.anaf.lang, "search");
    return (
      <div className={classNames(styles.page, "content")}>
        <Helmet>
          <title>AkzoNobel Art Foundation | {title} </title>
          <meta
            name="description"
            content={`AkzoNobel Art Foundation | ${title}`}
          />
        </Helmet>

        <h1 className={classNames("secundair", styles.mainTitle)}>{title}</h1>

        <div className={styles.searchBoxHolder}>
          {this.haveData() ? (
            <Searchbox test={this.state.test} advanced={this.state.advanced} />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }

  haveData() {
    const s = this.props.data.status;
    return s == statusTypes.LOADED || s == statusTypes.UPDATING;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
