import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

import { IData } from "../../reducers/dataReducer";
import { IAnaf } from "../../reducers/anafReducer";
import { updateLang } from "../../actions/anafActions";
import { statusTypes } from "../../actions/actionTypes";

import insta from "../../images/AAF_SVG_incons_AAF insta.svg";
import loc from "../../images/AAF_SVG_incons-3_AAF Lokatiemarker.svg";
import search from "../../images/AAF_SVG_incons_AAF Search.svg";
import * as styles from "./styles.module.css";
import { translate } from "../../tools/translateTools";
import { onTourPage } from "../../tools/pageTools";

interface State {}

interface parentProps {
  location: any;
}

interface StoreProps {
  anaf: IAnaf;
  data: IData;
}

interface DispatchProps {
  updateLang: Function;
}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
    data: state.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateLang: (lang) => {
    dispatch(updateLang(lang));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

const cookies = new Cookies();

export class Footer extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    if (this.onSplashPage()) {
      return <span></span>;
    }

    return (
      <div className={`${styles.footerHolder} content`}>
        <div className="d-flex">
          <div className={`flex-grow-1 d-flex ${this.searching()} bold`}>
            <a
              target="_blank"
              href="https://www.instagram.com/akzonobelartfoundation/"
            >
              <img src={insta} className={styles.instagram} />
            </a>
            {this.location()}
            {this.links()}
          </div>
          {this.opening()}
          {this.search()}
          {this.langPicker()}
        </div>
      </div>
    );
  }

  location() {
    if (this.onTourPage()) return <span></span>;
    return (
      <a href="/contact">
        <img src={loc} className={styles.loc} />
      </a>
    );
  }

  opening() {
    if (this.onTourPage()) return <span></span>;

    if (this.props.anaf.searchOpen) return <span></span>;

    return (
      <div
        className={`${styles.openingHolder} ${styles.lineConent} d-flex bold`}
      >
        <div className={styles.openingBlock}>
          Christian Neefestraat 2<br />
          1077WW Amsterdam
        </div>
        <div style={{ width: 110 }}>
          {translate(this.props.anaf.lang, "monday_friday")}
          <br />
          {translate(this.props.anaf.lang, "opening_times")}
        </div>
      </div>
    );
  }

  search() {
    if (this.onTourPage()) return <span></span>;

    return (
      <div className={styles.searchboxHolder}>
        <Link to="/search" className={styles.searchlink}>
          <img src={search} />
        </Link>
      </div>
    );
  }

  links() {
    if (this.onTourPage()) return <span></span>;

    if (this.props.anaf.dataset.status != statusTypes.LOADED)
      return <div></div>;
    const subs = this.props.anaf.dataset.menus;

    const links = subs[4].map((item) => (
      <Link key={item.stub} to={item.stub}>
        {this.props.anaf.lang == "EN" ? item.title_en : item.title_nl}
      </Link>
    ));

    let linkBlocks = new Array();
    for (let i = 0; i < links.length; i += 2) {
      linkBlocks.push(this.linkBlock(links, i));
    }

    return (
      <div className={`${styles.linkHolder} ${styles.lineConent} d-flex`}>
        {linkBlocks}
      </div>
    );
  }

  linkBlock(links, start) {
    return (
      <div className={styles.linkBlock}>
        {links[start]}
        <br />
        {links.length >= start + 1 ? links[start + 1] : ""}
      </div>
    );
  }

  onSplashPage() {
    return this.props.location.pathname == "/";
  }

  langPicker() {
    const targetLang = this.props.anaf.lang == "EN" ? "NL" : "EN";

    return (
      <div className={`${styles.langPickerHolder} ${this.searching()}`}>
        <div className="bold link" onClick={() => this.updateLang(targetLang)}>
          {targetLang}
        </div>
      </div>
    );
  }

  searching() {
    if (this.props.anaf.searchOpen) return styles.hide;
    return "";
  }

  updateLang(lang: string) {
    cookies.set("anaf_lang", lang, {
      path: "/",
      expires: new Date(Date.now() + 25920000000),
    });
    this.props.updateLang(lang);
  }

  haveData() {
    const s = this.props.data.status;
    return s == statusTypes.LOADED || s == statusTypes.UPDATING;
  }

  onTourPage() {
    return onTourPage(this.props.location.pathname);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
