import * as React from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { IData } from "../../reducers/dataReducer";
import { IAnaf } from "../../reducers/anafReducer";

import { statusTypes } from "../../actions/actionTypes";
import { closeLightbox, setIndexLightbox } from "../../actions/anafActions";

import Slider from "react-slick";
import Lightbox from "../lightbox/Lightbox";
import Tour from "./Tour";
import { SimpleContainerPage } from "../pages/ContentPage";

import EArtwork from "./EArtwork";
import { ScrollArrow, ScrollHolder } from "../pages/Scrolling";
import { SVGArrowRight, SVGArrowLeft } from "../../tools/svgTools";

import { ErrorBoundary } from "../ErrorBoundary";
import * as styles from "./styles.module.css";

interface State {
  show_intro: boolean;
  current: number;
}

interface parentProps {
  artwork_ids: number[];
  eid: number;
  page: string;
  history: any;
}

interface StoreProps {
  data: IData;
  anaf: IAnaf;
}

interface DispatchProps {
  closeLightbox: Function;
  setIndexLightbox: Function;
}

const mapStateToProps = (state) => {
  return {
    data: state.data,
    anaf: state.anaf,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeLightbox: () => {
    dispatch(closeLightbox());
  },
  setIndexLightbox: (index: number) => {
    dispatch(setIndexLightbox(index));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

export class ArtworkList extends React.Component<Props, State> {
  slider: any;
  refs: any;
  settings: any;

  constructor(props) {
    super(props);

    const page = parseInt(this.props.page);

    this.state = {
      show_intro: page == 0,
      current: page,
    };

    this.settings = {
      lazyLoad: "ondemand",
      // centerPadding: '0px',
      arrows: false,
      dots: false,
      infinite: true,
      // speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: page,
      // centerMode: true,
    };
  }

  render() {
    if (this.slider) this.slider.slickGoTo(this.props.page);

    if (!this.haveData()) {
      return <div>Loading...</div>;
    }

    let divStyle = {
      width: "100%",
      textAlign: "center" as "center",
    };

    const works = this.filter_hidden();

    return (
      <ScrollHolder id="slider">
        <div className="d-flex justify-content-between align-items-center">
          <ErrorBoundary>
            <div style={divStyle}>
              <Slider
                ref={(c) => (this.slider = c)}
                {...this.settings}
                beforeChange={(o, n) => this.beforeChangeHandler(o, n)}
              >
                {[
                  <div key="start">
                    <Tour eid={this.props.eid} intro={this.state.show_intro} />
                  </div>,
                  works.map((id, index) => this.holder(id, index)),
                ]}
              </Slider>
            </div>
          </ErrorBoundary>
        </div>

        {this.state.show_intro ? (
          <span></span>
        ) : (
          <div
            className={`link ${styles.arrowleft}`}
            onClick={(e) => this.previous()}
          >
            <ScrollArrow direction="left" target="" />
          </div>
        )}

        <div
          className={`link ${styles.arrowright}`}
          onClick={(e) => this.next()}
        >
          <ScrollArrow direction="right" target="" />
        </div>

        {this.props.anaf.lightboxIsOpen && <Lightbox artwork_ids={works} />}
      </ScrollHolder>
    );
  }

  filter_hidden() {
    return this.props.artwork_ids.filter(
      (id) => this.props.data.artworks.byIds[id] !== undefined
    );
  }

  holder(id, index) {
    let exhibition = this.props.data.exhibitions.byIds[this.props.eid];
    let annotates = exhibition.annotate[id];

    let text = this.props.anaf.lang == "EN" ? annotates.en : annotates.nl;
    const content = <div dangerouslySetInnerHTML={{ __html: text }}></div>;

    let h = this.props.anaf.height;

    let artwork = this.props.data.artworks.byIds[id];
    let artist = this.props.data.artists.byIds[artwork.artist_id];

    let lang = this.props.anaf.lang;
    let tech = lang == "EN" ? artwork.technique_en : artwork.technique_nl;

    return (
      <div key={id}>
        <SimpleContainerPage pageId={"artwork_" + id} next={"text_" + id}>
          <EArtwork artwork_id={id} key={id} index={index} />
        </SimpleContainerPage>
        <SimpleContainerPage pageId={"text_" + id} prev={"artwork_" + id}>
          <div className={styles.textcontent}>
            <div className={`bold ${styles.legend}`}>
              {artist.name} | {artwork.title}
              <br />
              {artwork.year} | {tech}
              <br />
              {this.dimen(artwork)}
            </div>
            {content}
          </div>
        </SimpleContainerPage>
      </div>
    );
  }

  dimen(artwork) {
    if (artwork.technique_id == 7 || artwork.dim_width == 0) return "";
    return `${this.round(artwork.dim_width)} x ${this.round(
      artwork.dim_height
    )} CM`;
  }

  round(dim) {
    if ((dim * 10) % 10 == 0) return Math.round(dim);
    return dim;
  }

  beforeChangeHandler(old_id, new_id) {
    const intro = new_id == 0;
    this.setState({
      show_intro: intro,
      current: new_id,
    });

    this.props.history.push(`/exhibition/${this.props.eid}/${new_id}`);

    if (old_id == 0 || new_id == 0) window.scrollTo(0, 0);
  }

  // Deprecated
  artworkList() {
    let list = new Array();
    this.props.artwork_ids.map((id) => {
      let artwork = this.props.data.artworks.byIds[id];
      list.push({ src: artwork.image, caption: artwork.title });
    });
    return list;
  }

  haveData() {
    const s = this.props.data.status;
    return s == statusTypes.LOADED || s == statusTypes.UPDATING;
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtworkList)
);
