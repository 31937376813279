import * as React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { IData } from "../../reducers/dataReducer";
import { IAnaf, ILang } from "../../reducers/anafReducer";

import { tagTypes } from "../../actions/actionTypes";
import {
  addTag,
  removeTag,
  removeAllTags,
  setCloudSearch,
} from "../../actions/anafActions";

import { techniqueList } from "../../tools/tagTools";
import { colourTags } from "../../tools/colorTools";
import { shuffle } from "../../tools/generalTools";
import { translate } from "../../tools/translateTools";

import * as styles from "./styles.module.css";

import search from "../../images/AAF_SVG_incons_AAF Search.svg";
import { SVGPlus, SVGCross } from "../../tools/svgTools";

interface State {
  results: ITagData[];
}

interface parentProps {}

interface StoreProps {
  anaf: IAnaf;
  data: IData;
}

interface DispatchProps {
  addTag: Function;
  removeTag: Function;
  removeAllTags: Function;
  setCloudSearch: Function;
}

interface ITagData {
  type: tagTypes;
  value: any;
  title: ILang;
}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
    data: state.data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addTag: (type: tagTypes, value: number, title: ILang) => {
    dispatch(addTag(type, value, title));
  },
  removeTag: (type: tagTypes, value: number) => {
    dispatch(removeTag(type, value));
  },
  removeAllTags: () => {
    dispatch(removeAllTags());
  },
  setCloudSearch: (val: boolean) => {
    dispatch(setCloudSearch(val));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

export class TagCloud extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    let results = new Array();

    results = [
      ...this.tagSelection(techniqueList(), tagTypes.TECHNIQUE, 1, 1, true),
      ...this.tagSelection(colourTags(), tagTypes.COLOUR, 0, 4, true),
      ...this.tagSelection(
        this.props.data.artworks.years,
        tagTypes.YEAR,
        0,
        1,
        false
      ),
    ];

    shuffle(results);

    this.state = {
      results: results,
    };
  }

  componentDidMount() {
    this.props.removeAllTags();
  }

  render() {
    return (
      <div>
        {this.allTags().map((tag, id) => this.tagButton(tag, id))}
        {this.props.anaf.cloudsearchOpen ? <span></span> : this.addButton()}
        {this.search()}
      </div>
    );
  }

  search() {
    return (
      <div>
        <div className={classNames(styles.tag, styles.search, "link", "bold")}>
          <Link to="/search?advanced">
            {translate(this.props.anaf.lang, "advanced_search")}{" "}
            <img src={search} />
          </Link>
        </div>
      </div>
    );
  }

  allTags() {
    let extra = new Array();
    this.props.anaf.tags.forEach((tag) => {
      if (!this.inStatelist(tag.type, tag.value)) {
        extra.push({
          type: tag.type,
          value: tag.value,
          title: tag.title,
          checked: true,
        });
      }
    });

    return this.state.results.concat(extra);
  }

  addButton() {
    return (
      <div
        className={classNames(styles.tag, styles.addbutton, "link", "bold")}
        onClick={() => this.clickedAdd()}
      >
        <div>
          <div className={classNames(styles.icon)}>{SVGPlus("#333")}</div>
        </div>
      </div>
    );
  }

  clickedAdd() {
    this.scrollToBottom();
    this.props.setCloudSearch(true);
  }

  tagSelection(tags, type, start, count, obj) {
    let results = new Array();
    let options = tags.map((option, id) => id);

    for (let i = 0; i < count; i++) {
      let selected = Math.round(
        start + Math.random() * (options.length - 1 - start)
      );

      let value, title: ILang;

      if (obj) {
        title = {
          en: tags[options[selected]].en,
          nl: tags[options[selected]].nl,
        };
        value = options[selected];
      } else {
        title = { en: tags[options[selected]], nl: tags[options[selected]] };
        value = tags[options[selected]];
      }

      results.push({ type: type, value: value, title: title });
      options.splice(selected, 1);
    }
    return results;
  }

  tagButton(tag: ITagData, id: number) {
    let checked = this.inTaglist(tag.type, tag.value);

    return (
      <div
        key={id}
        className={classNames(styles.tag, "link", "bold")}
        onClick={() => {
          this.toggleTag(tag);
        }}
      >
        <div>
          <div
            className={classNames(styles.icon, {
              [`${styles.rotate}`]: checked,
            })}
          >
            {SVGPlus("#8bffec")}
          </div>{" "}
          {this.props.anaf.lang == "EN" ? tag.title.en : tag.title.nl}
        </div>
      </div>
    );
  }

  toggleTag(tag) {
    if (this.inTaglist(tag.type, tag.value))
      this.props.removeTag(tag.type, tag.value);
    else this.props.addTag(tag.type, tag.value, tag.title);

    this.scrollToBottom();
  }

  inTaglist(type: tagTypes, value: number) {
    let found = false;
    this.props.anaf.tags.forEach((tag) => {
      if (tag.type == type && tag.value == value) {
        found = true;
        return;
      }
    });
    return found;
  }

  inStatelist(type: tagTypes, value: number) {
    let found = false;
    this.state.results.forEach((tag) => {
      if (tag.type == type && tag.value == value) {
        found = true;
        return;
      }
    });
    return found;
  }

  scrollToBottom() {
    let endpoint = document.getElementById("gobutton");
    endpoint.scrollIntoView({ behavior: "smooth" });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagCloud);
