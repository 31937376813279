import * as React from "react";

import { scrollToID } from "../../tools/pageTools";

import * as styles from "./styles.module.css";
import arrow_down from "../../images/AAF_SVG_incons_AAF Arrow 180.svg";
import arrow_up from "../../images/AAF_SVG_incons_AAf Arrow 0.svg";

import arrow_left from "../../images/AAF_SVG_incons-3_AAF Arrow -90.svg";
import arrow_right from "../../images/AAF_SVG_incons-3_AAF Arrow 90.svg";

interface ScrollArrowProps {
  direction: string;
  target: string;
}

interface ScrollState {
  scrolling: boolean;
}

export class ScrollArrow extends React.Component<
  ScrollArrowProps,
  ScrollState
> {
  timer: any;

  constructor(props) {
    super(props);

    this.state = {
      scrolling: false,
    };

    this.scrolling = this.scrolling.bind(this);
  }

  componentDidMount() {
    document.addEventListener("scroll", this.scrolling);
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.scrolling);
  }

  render() {
    if (this.props.direction == "up" || this.props.direction == "down")
      return this.updown();
    return this.side();
  }

  updown() {
    const up = this.props.direction == "up";

    if (!this.props.target) return <span></span>;

    const style = up ? styles.top : styles.bot;
    const flex = up
      ? "d-flex align-items-start justify-content-center"
      : "d-flex align-items-end justify-content-center";

    const scrol = this.state.scrolling ? styles.scrolling : "";

    return (
      <div className={`${style} ${flex}`}>
        <div
          style={{ textAlign: "center" as "center" }}
          className="link"
          onClick={() => scrollToID(this.props.target)}
        >
          <img
            className={`${styles.arrow} ${scrol}`}
            src={up ? arrow_up : arrow_down}
          />
        </div>
      </div>
    );
  }

  side() {
    const right = this.props.direction == "right";
    const scrol = this.state.scrolling ? styles.scrolling : "";

    return (
      <img
        className={`${styles.arrow} ${scrol}`}
        src={right ? arrow_right : arrow_left}
      />
    );
  }

  scrolling() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.endScrolling(), 200);

    this.setState({
      scrolling: true,
    });
  }

  endScrolling() {
    this.setState({
      scrolling: false,
    });
  }
}

interface ScrollHolderProps {
  id: string;
  className?: string;
  style?: any;
}

export class ScrollHolder extends React.Component<ScrollHolderProps, {}> {
  render() {
    return (
      <div
        id={`page_${this.props.id}`}
        className={this.props.className}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}
