// extracted by mini-css-extract-plugin
var _1 = "cj194AVxkZQD0fxySeej";
var _2 = "ONMOeBvme0ptPrDPbMxu";
var _3 = "_nIE1WwrshXFNEqvtGs0";
var _4 = "wCq9PAY0p5e_ycxAMr8c";
var _5 = "dlwGNI_USSe5egSBuCQ6";
var _6 = "RA2tKAUwv7potIvbw40A";
var _7 = "d89t0XTfZNrZSy9tr7wb";
var _8 = "av3dgvgjqWDDDnz0HCZM";
var _9 = "YESg60e9WmIiKyJuZNID";
export { _1 as "arrow", _2 as "contentHolder", _3 as "func", _4 as "intro", _5 as "mainTitle", _6 as "masonry", _7 as "name", _8 as "padding", _9 as "text" }
