// extracted by mini-css-extract-plugin
var _1 = "StJiSzJwSnBnyeZvO814";
var _2 = "cpC8OIo5xZjxHilcS_cS";
var _3 = "ws91cepd1DlC0Gu1pwNR";
var _4 = "ZSARdL1RqrwvJ5h7lBqF";
var _5 = "eTUj2j5pLv8po6aOd7WN";
var _6 = "Ed_rlJ81EFQfZXsXzgDW";
var _7 = "EhwYxE2XBOrkCZfpQeEw";
var _8 = "yzdOGE2BHInaCYAB8Ptv";
var _9 = "CBtQn63aH7hYxeFTezt3";
var _a = "xj5yT4G34vwgcoyM4L85";
var _b = "rGKoldYtdqed8AC8g0kb";
var _c = "tbt0hO5Ym5nODMgr_rAb";
var _d = "XqEwBGKhVvrxZyQ7OLNV";
var _e = "LaGqkwJxG48EiLM_bhst";
var _f = "qafwjRZ1P5uRdlZ7YQci";
var _10 = "b0yl2aLkeHMnUmq8k7Q0";
var _11 = "HqmhJWGd7Fr206R2ULey";
var _12 = "adT9JY4myEyPbEYfqVE0";
var _13 = "XTvLuFlNUuaED2Sp0IWu";
export { _1 as "about", _2 as "bigText", _3 as "colab", _4 as "leftContentHolder", _5 as "leftSide", _6 as "leftSideArrow", _7 as "leftSideArrowSmall", _8 as "leftSideH1", _9 as "leftSideShow", _a as "logoFull", _b as "logoHolder", _c as "rightSide", _d as "rightSideArrow", _e as "rightSideArrowHolder", _f as "rightSideArrowSmall", _10 as "rightSideH1", _11 as "rightSideShow", _12 as "showcontent", _13 as "std" }
