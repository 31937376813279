import * as React from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import * as CONST from "../exhibition/consts";

import classNames from "classnames";

import { IData, IExhibitionId } from "../../reducers/dataReducer";
import { IAnaf, ICrumb } from "../../reducers/anafReducer";
import { statusTypes } from "../../actions/actionTypes";
import { resetCrumbs, addCrumbs } from "../../actions/anafActions";
import { translate } from "../../tools/translateTools";

import { Button } from "../buttons/Button";

import { SVGArrowRight } from "../../tools/svgTools";
import * as styles from "./styles.module.css";

interface State {
  maxItems: number;
}

interface parentProps {}

interface StoreProps {
  data: IData;
  anaf: IAnaf;
}

interface DispatchProps {
  resetCrumbs: Function;
  addCrumbs: Function;
}

const mapStateToProps = (state) => {
  return {
    data: state.data,
    anaf: state.anaf,
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetCrumbs: () => {
    dispatch(resetCrumbs());
  },
  addCrumbs: (crumb: ICrumb[]) => {
    dispatch(addCrumbs(crumb));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

export class ExhibitionList extends React.Component<Props, State> {
  componentDidMount() {
    this.props.resetCrumbs();
    let home: ICrumb = { title: "home", link: "/home", bg: "#eba550" };
    let exhibitions: ICrumb = {
      title: "past_exhibitions",
      link: "/exhibitions",
      bg: "#eba550",
    };

    this.props.addCrumbs([home, exhibitions]);
  }

  constructor(props) {
    super(props);
    this.state = {
      maxItems: 50,
    };
  }

  render() {
    if (!this.haveData()) {
      return <div>Loading...</div>;
    }

    const tit =
      this.props.anaf.lang == "EN"
        ? "Past Exhibitions"
        : "Voorafgaande tentoonstellingen";
    return (
      <div className={`${styles.page} content`}>
        <Helmet>
          <title>AkzoNobel Art Foundation | {tit} </title>
          <meta
            name="description"
            content={`AkzoNobel Art Foundation | ${tit}`}
          />
        </Helmet>

        <h1 className={classNames("secundair", styles.mainTitle)}>
          {this.props.anaf.lang == "EN" ? (
            <span>
              Past
              <br /> Exhibitions
            </span>
          ) : (
            "Voorafgaande tentoonstellingen"
          )}
        </h1>
        {this.limited().map((item) => this.eventLine(item))}

        {this.loadMore()}
      </div>
    );
  }

  loadMore() {
    const lang = this.props.anaf.lang;

    if (this.sorted().length > this.state.maxItems)
      return (
        <div onClick={() => this.updateMax()}>
          <Button
            arrow="right"
            color="##eba550"
            text={translate(lang, "load_more")}
          />
        </div>
      );

    return <span></span>;
  }

  eventLine(item) {
    const lang = this.props.anaf.lang;
    const title = lang == "EN" ? item.title_en : item.title_nl;
    let content = title.replace("[enter]", " ");

    return (
      <div key={item.id}>
        <Link to={"/exhibition/" + item.id}>
          <div className="row link">
            <div
              className={`${styles.title} ${styles.border} col-10 col-md-5 bold offset-md-3 `}
            >
              {content}
            </div>
            <div
              className={`${styles.icon} ${styles.border} col-2 col-md-1 pr-0 pl-0`}
            >
              <div className={styles.iconHolder}>{SVGArrowRight("#333")}</div>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  // limit size based on max items
  limited() {
    let items: any = this.sorted();
    const max = items.length;

    return items.slice(0, Math.min(this.state.maxItems, max));
  }

  // sort based on opening date
  sorted() {
    let unsorted = this.filtered();
    unsorted.sort((a, b) => {
      let am =
        a.opened_at.substring(0, 4) +
        a.opened_at.substring(5, 7) +
        a.opened_at.substring(8, 10);
      let bm =
        b.opened_at.substring(0, 4) +
        b.opened_at.substring(5, 7) +
        b.opened_at.substring(8, 10);

      if (am < bm) return 1;
      if (am > bm) return -1;
      return 0;
    });

    return unsorted;
  }

  filtered() {
    const items: IExhibitionId = this.props.data.exhibitions.byIds;
    let filtered = new Array();
    Object.keys(items).map((id) => {
      if (items[id].etype == CONST.EXHIBITION && !items[id].active)
        filtered.push(items[id]);
    });
    return filtered;
  }

  updateMax() {
    this.setState({
      maxItems: this.state.maxItems + 5,
    });
  }

  haveData() {
    const s = this.props.data.status;
    return s == statusTypes.LOADED || s == statusTypes.UPDATING;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitionList);
