import * as React from "react";

import { connect } from "react-redux";
import { IData, IArtwork } from "../../reducers/dataReducer";
import { IAnaf } from "../../reducers/anafReducer";

import { closeLightbox, setIndexLightbox } from "../../actions/anafActions";

import ClassNames from "classnames";

import Masonry from "react-masonry-component";
import Lightbox from "../lightbox/Lightbox";

import { withRouter } from "react-router-dom";
import MArtwork from "./MArtwork";

import "./globalMasonry.css";

import * as styles from "./styles.module.scss";

interface State {
  maxItems: number;
  currentIds: number[];
}

interface parentProps {
  artwork_ids: number[];
  match: any;
  location: any;
  solo: boolean;
}

interface StoreProps {
  data: IData;
  anaf: IAnaf;
}

interface DispatchProps {
  closeLightbox: Function;
  setIndexLightbox: Function;
}

const mapStateToProps = (state) => {
  return {
    data: state.data,
    anaf: state.anaf,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeLightbox: () => {
    dispatch(closeLightbox());
  },
  setIndexLightbox: (index: number) => {
    dispatch(setIndexLightbox(index));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

const masonryOptions = {
  transitionDuration: 800,
  columnWidth: ".grid-sizer",
  gutter: ".gutter-sizer",
  itemSelector: ".grid-item",
  percentPosition: true,
};

export class MasonryList extends React.Component<Props, State> {
  masonry: any;

  constructor(props) {
    super(props);
    this.state = {
      maxItems: 5,
      currentIds: this.props.artwork_ids,
    };
    this.updateMax = this.updateMax.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.updateMax);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateMax);
  }

  updateMax() {
    let endpoint = document.getElementById("endpoint");
    let bodyRect = document.body.getBoundingClientRect();
    let elemRect = endpoint.getBoundingClientRect();
    let offset = elemRect.top - bodyRect.top;
    let wheight = window.innerHeight;
    let delta = wheight / 2;

    if (window.scrollY + wheight + delta > offset) {
      if (this.state.maxItems < this.props.artwork_ids.length)
        this.setState({
          maxItems: this.state.maxItems + 3,
        });
    }
  }

  static getDerivedStateFromProps(nextProps: parentProps, prevState: State) {
    if (
      JSON.stringify(nextProps.artwork_ids) !=
      JSON.stringify(prevState.currentIds)
    ) {
      return {
        maxItems: 5,
        currentIds: nextProps.artwork_ids,
      };
    }
    return null;
  }

  render() {
    let images = this.artworkList();
    let cur = this.props.anaf.lightboxIndex;
    let next = (this.props.anaf.lightboxIndex + 1) % images.length;
    let prev =
      (this.props.anaf.lightboxIndex + images.length - 1) % images.length;

    return (
      <div
        className={ClassNames("content", styles.masonry, {
          [`${styles.solo}`]: this.props.solo,
        })}
      >
        <Masonry
          ref={function(c) {
            this.masonry = this.masonry || c.masonry;
          }.bind(this)}
          className={"my-gallery-class"}
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        >
          {[
            <div className="grid-sizer" key="sizer"></div>,
            <div className="gutter-sizer" key="gsizer"></div>,
            ...this.artworks(),
          ]}
        </Masonry>

        {this.props.anaf.lightboxIsOpen && (
          <Lightbox artwork_ids={this.removeSets()} />
        )}

        <div id="endpoint"></div>
      </div>
    );
  }

  artworkList() {
    let ids = this.props.artwork_ids;
    let list = new Array();
    Object.keys(ids).map((id) => {
      let artwork = this.props.data.artworks.byIds[ids[id]];
      list.push({
        src: artwork.image,
        caption: artwork.title,
      });
    });
    return list;
  }

  artworks() {
    let ids = this.removeSets();
    let elements = new Array();
    let i = 0;

    ids.map((id, index) => {
      if (i < this.state.maxItems)
        elements.push(this.holder(id, id, index, ids.length));
      i++;
    });
    return elements;
  }

  removeSets() {
    let ids = this.props.artwork_ids;
    let elements = new Array();

    Object.keys(ids).map((id, index) => {
      let artwork = this.props.data.artworks.byIds[ids[id]];
      let add_work = true;

      if (artwork.part_of_set) {
        add_work = false;
        if (artwork.subset[0] == parseInt(ids[id])) add_work = true;
      }
      if (add_work) elements.push(ids[id]);
    });
    return elements;
  }

  reflow() {
    setTimeout(() => this.masonry.layout(), 200);
  }

  holder(artwork_id, key, index, count) {
    const subCont = {
      width: "100%",
      display: "flex",
    };

    let cn = "image-element-class grid-item";
    const artwork = this.props.data.artworks.byIds[artwork_id];

    if (artwork.dim_width > 400 || count == 1) {
      cn += " b5";
    }

    return (
      <div key={key} className={cn}>
        <div style={subCont}>
          <MArtwork
            handler={() => this.reflow()}
            artwork_id={artwork_id}
            index={index}
            count={count}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MasonryList)
);
