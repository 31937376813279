import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import * as CONST from "../exhibition/consts";

import { IArtwork, IArtist, IExhibition } from "../../reducers/dataReducer";
import { IAnaf, ILang } from "../../reducers/anafReducer";

import { tagTypes } from "../../actions/actionTypes";
import { addTag, removeTag, openLightbox } from "../../actions/anafActions";

import {
  pColour,
  textColour,
  colourTags,
  colourFlags,
} from "../../tools/colorTools";
import { techniqueList } from "../../tools/tagTools";
import { ArtistURL, ExhibionURL } from "../../tools/pageTools";
import {
  SVGPlus,
  SVGCross,
  SVGFullscreen,
  SVGArrowRight,
  SVGArrowLeft,
} from "../../tools/svgTools";

import { Transition } from "react-transition-group";

import * as styles from "./MAstyles.module.css";

interface State {
  isFlipped: boolean;
  showButtons: boolean;
  mounted: boolean;
  loaded: boolean;
  sindex: number;
}

interface parentProps {
  artwork_id: number;
  index: number;
  count: number;
  handler: Function;
}

interface StoreProps {
  anaf: IAnaf;
  artworks: IArtwork[];
  artists: IArtist[];
  exhibitions: IExhibition[];
}

interface DispatchProps {
  addTag: Function;
  removeTag: Function;
  openLightbox: Function;
}

const mapStateToProps = (state) => {
  return {
    anaf: state.anaf,
    artworks: state.data.artworks.byIds,
    artists: state.data.artists.byIds,
    exhibitions: state.data.exhibitions.byIds,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addTag: (type: tagTypes, value: number, title: ILang) => {
    dispatch(addTag(type, value, title));
  },
  removeTag: (type: tagTypes, value: number) => {
    dispatch(removeTag(type, value));
  },
  openLightbox: (index: number, sindex: number) => {
    dispatch(openLightbox(index, sindex));
  },
});

type Props = StoreProps & DispatchProps & parentProps;

export class MArtwork extends React.Component<Props, State> {
  refs: any;
  artwork: IArtwork;
  artist: IArtist;
  tags: any[];

  constructor(props) {
    super(props);

    this.artwork = this.props.artworks[this.props.artwork_id];
    this.artist = this.props.artists[this.artwork.artist_id];

    this.state = {
      mounted: false,
      isFlipped: false,
      showButtons: false,
      loaded: false,
      sindex: 0,
    };
  }

  componentDidMount() {
    let w = this.refs.child.parentNode.clientWidth;
    this.setState({
      mounted: true,
    });
  }

  componentWillUnmount() {
    this.setState({
      mounted: false,
    });
  }

  render() {
    //const a = this.artwork.subset? this.props.artworks[this.artwork.subset[this.state.sindex]] : this.artwork

    // let size = 100 * scale
    // let hscale = a.height / a.width
    // let newheight = hscale * size

    // const w = size / 100 * maxw
    // const h = newheight / 100 * maxw

    const a = this.artwork;
    const dim = this.calcDim();

    console.log(this.image());

    const img = {
      width: dim.w,
      height: dim.h,
      minHeight: 100,
      backgroundColor: pColour(this.artwork.colours),
      backgroundImage: `url("${this.image()}")`,
      backgroundSize: "cover",
      zIndex: this.state.isFlipped ? 10 : 0,
    };

    let pos = "";
    if (a.id % 3 == 1) pos = "justify-content-start";

    if (a.id % 3 == 2) pos = "justify-content-end";

    if (a.id % 3 == 0) pos = "justify-content-center";

    if (this.props.count == 1) {
      pos = "justify-content-center";
    }

    let cn = "d-flex " + pos;

    // <img
    //   onClick={(e) => this.doFlip(e)}
    //   src={a.image}
    //   style={img}
    //   onLoad={() => this.imageLoaded()}
    //   />
    //
    const holder = {
      width: "100%",
      minHeight: dim.h,
    };

    return (
      <div className="d-flex flex-column " style={holder}>
        <div ref="child" className={cn}>
          <div
            className={`link ${styles.bgimg}`}
            style={img}
            onClick={(e) => this.doFlip(e)}
          >
            {this.backside(dim.w, dim.h)}
          </div>
        </div>
        {a.part_of_set ? this.setnav(cn) : <span></span>}
        <div className={cn}>{this.title(dim.w, cn)}</div>
      </div>
    );
  }

  calcDim() {
    let a = this.artwork;

    if (a.part_of_set) {
      let id = this.artwork.subset[this.state.sindex];
      a = this.props.artworks[id];
    }

    let maxw = 100;
    if (this.state.mounted) {
      maxw = this.refs.child.parentNode.clientWidth;
    }
    const dw = a.dim_width;
    const dh = a.dim_height;

    let maxh = this.props.anaf.height * 0.8;

    if (dh > 200) maxh = this.props.anaf.height * 1.5;

    let w = 100;
    let h = 100;

    let maxscale = a.width / maxw;
    let newh = a.height / maxscale;

    // if the image won't fit vertically, scale down until it does
    if (newh > maxh) {
      maxscale = a.height / maxh;
      w = a.width / maxscale;
    } else {
      w = maxw;
    }

    h = a.height / maxscale;

    let scale = 0.5;

    if (dw > 30) {
      scale = 0.6;
    }

    if (dw > 60) scale = 0.7;

    if (dw > 120) scale = 0.8;

    if (dw > 300) scale = 1;

    if (dw > 400) {
      scale = 1;
    }

    // fullsize on mobile
    if (window.innerWidth < 770) {
      scale = 1;
    }

    w = w * scale;
    h = h * scale;

    return { w: w, h: h };
  }

  preload() {
    return (
      <div style={{ display: "none" }}>
        {this.artwork.subset.map((id) => (
          <img key={id} src={this.props.artworks[id].image} />
        ))}
      </div>
    );
  }

  image() {
    let a = this.artwork;
    if (!a.part_of_set) return this.artwork.image;
    let id = this.artwork.subset[this.state.sindex];
    let artwork = this.props.artworks[id];
    return artwork.image;
  }

  imageLoaded() {
    this.setState({
      loaded: true,
    });

    setTimeout(() => this.setVisible(), 2500);
  }

  setnav(cn) {
    let c = this.state.sindex;
    let size = this.artwork.subset.length;

    const arrowStyle = {
      strokeWidth: 3,
      width: 12,
      float: "right",
      paddingTop: 5,
    };
    return (
      <div className={cn}>
        <div className="link" onClick={() => this.prevSub()}>
          {SVGArrowLeft("#333", arrowStyle)}
        </div>
        <div className={`bold ${styles.subnav}`}>
          {c + 1} / {size}
        </div>
        <div className="link" onClick={() => this.nextSub()}>
          {SVGArrowRight("#333", arrowStyle)}
        </div>
        <div>{this.preload()}</div>
      </div>
    );
  }

  title(w: number, cn) {
    const titleStyle = {
      fontSize: "0.75rem",
      lineHeight: "0.95rem",
      width: w,
      paddingTop: 5,
    };

    let title = "";
    if (this.artwork.part_of_set) {
      let id = this.artwork.subset[this.state.sindex];
      let artwork = this.props.artworks[id];
      title = artwork.title;
    } else title = this.artwork.title;

    let dim = "";
    let artw = Number(this.artwork.dim_width);
    let arth = Number(this.artwork.dim_height);
    let artd = Number(this.artwork.dim_depth);

    if (artw > 0 && arth > 0) {
      dim = `${arth} x ${artw}`;
    }
    if (artd > 0) dim += ` x ${artd}`;

    if (dim.length > 0) dim += " CM";

    let tech =
      this.props.anaf.lang == "EN"
        ? this.artwork.technique_en
        : this.artwork.technique_nl;

    if (tech.length > 0) tech = tech.charAt(0).toUpperCase() + tech.slice(1);

    return (
      <div className={`bold ${styles.title}`} style={titleStyle}>
        {this.artist.name} | {title} | {this.artwork.year} <br />
        {tech} | {dim}
      </div>
    );
  }

  backside(w, h) {
    const duration = 350;

    let backStyle = {
      width: w,
      minWidth: 180,
      minHeight: 0,
      height: 0,
      backgroundColor: pColour(this.artwork.colours),
      color: textColour(this.artwork.colours),
      textAlign: "left" as "left",
      overflow: "hidden",
      transition: `min-height ${duration}ms ease-in-out`,
    };

    this.tags = new Array();

    let techniques = techniqueList();
    let technique = techniques[this.artwork.technique_id];
    let tname: ILang = { en: technique.en, nl: technique.nl };
    let tyear: ILang = {
      en: "" + this.artwork.year,
      nl: "" + this.artwork.year,
    };

    this.tags.push(
      this.Tag(tagTypes.TECHNIQUE, this.artwork.technique_id, tname)
    );
    this.tags.push(this.Tag(tagTypes.YEAR, this.artwork.year, tyear));
    this.themeTags();
    this.showColourTags(this.artwork.ctags);

    const transitionStyles = {
      entering: { minHeight: h, height: 0 },
      entered: { minHeight: h, height: "auto", overflow: "auto" },
      exiting: {
        minHeight: 0,
        height: 0,
        overflow: "hidden",
      },
      exited: {
        minHeight: 0,
        height: 0,
        overflow: "hidden",
      },
    };

    return (
      <div key="back">
        <Transition in={this.state.isFlipped} timeout={duration}>
          {(sx) => (
            <div
              style={{ ...backStyle, ...transitionStyles[sx] }}
              className={`d-flex flex-column back`}
            >
              <div className="p-2">
                <div>{this.tags}</div>
                {this.ArtistLink()} {this.ExhibitionLinks()}
              </div>
              <div className="mt-auto ml-auto">
                <div
                  style={{
                    cursor: "pointer",
                    margin: "5px 5px",
                    padding: "5px 5px",
                  }}
                  onClick={(e) => this.openLightbox(e)}
                >
                  {SVGFullscreen(textColour(this.artwork.colours), {
                    width: w * 0.1,
                    strokeWidth: 1,
                  })}
                </div>
              </div>
            </div>
          )}
        </Transition>
      </div>
    );
  }

  showColourTags(ctags: number) {
    const tags = colourFlags(ctags);
    const taglist = colourTags();

    tags.map((id) => {
      let name: ILang = { en: taglist[id].en, nl: taglist[id].nl };
      this.tags.push(this.Tag(tagTypes.COLOUR, id, name));
    });
  }

  themeTags() {
    this.artwork.exhibitions.map((id) => {
      const exhibition = this.props.exhibitions[id];
      if (exhibition.etype != CONST.THEME) return null;

      let nl = exhibition.title_nl.replace("[enter]", " ");
      let en = exhibition.title_en.replace("[enter]", " ");
      let name: ILang = { en: en, nl: nl };

      this.tags.push(this.Tag(tagTypes.THEME, id, name));
    });
  }

  ArtistLink() {
    const colorStyle = {
      color: pColour(this.artwork.colours),
    };

    const aristStyle = {
      borderColor: textColour(this.artwork.colours),
      backgroundColor: textColour(this.artwork.colours),
    };

    const svgStyle = {
      display: "inline-block",
      width: 16,
    };

    const arrowStyle = {
      strokeWidth: 4,
      height: 15,
      margin: "0px 5px 2px 0px",
    };

    if (this.artist) {
      return (
        <Link
          style={colorStyle}
          className="bold link"
          to={ArtistURL(this.artist)}
        >
          <div className={styles.tag} style={aristStyle}>
            <div style={svgStyle}>
              {SVGArrowRight(pColour(this.artwork.colours), arrowStyle)}
            </div>{" "}
            {this.artist.name}
          </div>
        </Link>
      );
    }
    return <div style={colorStyle}>{this.artist.name}</div>;
  }

  ExhibitionLinks() {
    const colorStyle = {
      color: pColour(this.artwork.colours),
    };

    const aristStyle = {
      borderColor: textColour(this.artwork.colours),
      backgroundColor: textColour(this.artwork.colours),
    };

    const svgStyle = {
      display: "inline-block",
      width: 16,
    };

    const arrowStyle = {
      strokeWidth: 4,
      height: 15,
      margin: "0px 5px 2px 0px",
    };

    let links = new Array();
    this.artwork.exhibitions.map((id) => {
      const exhibition = this.props.exhibitions[id];

      if (exhibition.etype == CONST.THEME) return null;

      let name =
        this.props.anaf.lang == "EN"
          ? exhibition.title_en
          : exhibition.title_nl;
      let content = name.replace("[enter]", " ");

      links.push(
        <Link
          key={"exhibition/" + id}
          style={colorStyle}
          className="bold link"
          to={ExhibionURL(exhibition)}
        >
          <div className={styles.tag} style={aristStyle}>
            <div style={svgStyle}>
              {SVGArrowRight(pColour(this.artwork.colours), arrowStyle)}
            </div>{" "}
            {content}
          </div>
        </Link>
      );
    });

    return links;
  }

  Tag(type: tagTypes, value: number, title: ILang) {
    const tagStyle = {
      borderColor: textColour(this.artwork.colours),
      backgroundColor: textColour(this.artwork.colours),
      color: pColour(this.artwork.colours),
    };

    const svgStyle = {
      // display: 'inline-block',
    };

    let trans_title = this.props.anaf.lang == "EN" ? title.en : title.nl;

    const AddButton = withRouter(({ history }) => (
      <div
        className={`${styles.tag} ${styles.add} bold`}
        style={tagStyle}
        onClick={() => {
          this.props.addTag(type, value, title);
          history.push("/explore");
        }}
      >
        <div style={svgStyle}>
          {SVGPlus(pColour(this.artwork.colours), {})} {trans_title}
        </div>
      </div>
    ));

    const RemoveButton = withRouter(({ history }) => (
      <div
        className={`${styles.tag} ${styles.remove} bold`}
        style={tagStyle}
        onClick={() => {
          this.props.removeTag(type, value);
          history.push("/explore");
        }}
      >
        <div style={svgStyle}>
          {SVGCross(pColour(this.artwork.colours), {})} {trans_title}
        </div>
      </div>
    ));

    if (this.inTaglist(type, value))
      return <RemoveButton key={type + "/" + value} />;
    else return <AddButton key={type + "/" + value} />;
  }

  inTaglist(type: tagTypes, value: number) {
    let found = false;
    this.props.anaf.tags.forEach((tag) => {
      if (tag.type == type && tag.value == value) {
        found = true;
        return;
      }
    });
    return found;
  }

  doFlip(e) {
    e.preventDefault();
    this.setState((prevState) => ({ isFlipped: !prevState.isFlipped }));
  }

  setVisible() {
    this.setState((prevState) => ({ isFlipped: true }));
  }

  openLightbox(e) {
    this.props.openLightbox(this.props.index, this.state.sindex);
    e.stopPropagation();
  }

  nextSub() {
    let next = (this.state.sindex + 1) % this.artwork.subset.length;
    this.setState({
      sindex: next,
    });

    this.props.handler();
  }

  prevSub() {
    let prev =
      (this.artwork.subset.length + this.state.sindex - 1) %
      this.artwork.subset.length;
    this.setState({
      sindex: prev,
    });

    this.props.handler();
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MArtwork);
