import * as React from "react";
import { connect } from "react-redux";

import { IData } from "../../reducers/dataReducer";
import { colorlist } from "../../tools/colorTools";

import { TitlePage2 } from "../pages/TitlePage";
import {
  ContentPage,
  SimpleContainerPage,
  parseContent,
} from "../pages/ContentPage";
import * as styles from "./styles.module.css";

interface State {}

interface parentProps {
  eid: number;
  intro: boolean;
}

interface StoreProps {
  data: IData;
  lang: string;
}

interface DispatchProps {}

const mapStateToProps = (state) => {
  return {
    data: state.data,
    lang: state.anaf.lang,
  };
};

const mapDispatchToProps = (dispatch) => ({});

type Props = StoreProps & DispatchProps & parentProps;

export class Simple extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // here be dragons
  // page flow has be swapped around too much
  // paging currently is a hack

  render() {
    return <div>{this.showMore()}</div>;
  }

  showMore() {
    const e = this.props.data.exhibitions.byIds[this.props.eid];
    const c = colorlist()[e.colour];
    const title = this.props.lang == "EN" ? e.title_en : e.title_nl;
    const preview_text =
      this.props.lang == "EN"
        ? e.description_preview_en
        : e.description_preview_nl;
    const footer =
      this.props.lang == "EN" ? e.quote_footer_en : e.quote_footer_nl;
    const preview = (
      <div>
        <p>&ldquo;{preview_text}&rdquo;</p>
      </div>
    );

    const show_video = e.video ? e.video.length > 0 : false;
    const show_img = e.image ? e.image.length > 0 : false;

    const content_text =
      this.props.lang == "EN" ? e.description_en : e.description_nl;
    const parsed = parseContent(content_text);

    return (
      <div>
        {this.title(title, show_img, c)}
        {show_img ? this.img(e) : <span></span>}
        {this.quotePage(
          preview_text,
          footer,
          `rgb(${c.r},${c.g},${c.b})`,
          show_img,
          parsed.length
        )}
        {this.content(parsed, show_video)}
        {show_video ? this.video(e.video, parsed) : <span></span>}
      </div>
    );
  }

  title(title, show_img, c) {
    let content = parseContent(title)[0];
    return (
      <div className={styles.landingframe}>
        <TitlePage2
          bgColour={`rgb(${c.r},${c.g},${c.b})`}
          pageId="p0"
          next={show_img ? "img" : "p1"}
          title={content}
        />
      </div>
    );
  }

  content(parsed, show_video) {
    const e = this.props.data.exhibitions.byIds[this.props.eid];
    const c = colorlist()[e.colour];

    return (
      <div>
        {parsed.map((content, id) => {
          let next = show_video ? "video" : "";
          return (
            <ContentPage
              extraClasses={[styles.normaltext]}
              textColour={`rgb(${c.r},${c.g},${c.b})`}
              pageId={"c" + id}
              prev={id == 0 ? "p1" : "c" + (id - 1)}
              next={id == parsed.length - 1 ? next : "c" + (id + 1)}
              content={
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
              }
            />
          );
        })}
      </div>
    );
  }

  quotePage(quote, footer, color, show_img, pages) {
    return (
      <SimpleContainerPage pageId="p1" next="c0" prev={show_img ? "img" : "p0"}>
        <div
          className={`${styles.contentHolder} ${styles.quotePageContentHolder} d-flex align-items-center`}
        >
          <div className={styles.quotePageContent}>
            <p className={`${styles.quote} bold`} style={{ color: color }}>
              &ldquo;{quote}&rdquo;
            </p>
            <p className={`${styles.quotefoot}`} style={{ color: color }}>
              {footer}
            </p>
          </div>
        </div>
      </SimpleContainerPage>
    );
  }

  img(e: any) {
    return (
      <SimpleContainerPage pageId="img" next="p1" prev="p0">
        <div
          className={`${styles.contentHolder} ${styles.quotePageContentHolder} d-flex align-items-center`}
        >
          <div className={styles.quotePageContent}>
            <img src={e.image} className={styles.coverimg} />
            <p className={styles.imgauthor}>{e.image_author} </p>
          </div>
        </div>
      </SimpleContainerPage>
    );
  }

  video(video: string, parsed) {
    let h = window.innerHeight * 0.6;
    const source = "https://player.vimeo.com/video/" + video;

    return (
      <SimpleContainerPage pageId="video" prev={"c" + (parsed.length - 1)}>
        <iframe
          src={source}
          width="100%"
          height={h}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </SimpleContainerPage>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Simple);

//<Vidplayer url={source} />
