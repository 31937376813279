// extracted by mini-css-extract-plugin
var _1 = "WtsG19JNhaSv6Ywwnd_0";
var _2 = "dQAtmKqy6oEHpj2LVcVV";
var _3 = "gtFMTzyfnAZOSFcwPg9D";
var _4 = "LISyDHx0ayCdbT52ziXm";
var _5 = "UcJCFwATt6gyHk1o4q4z";
var _6 = "O19TiL4qtmipuQLkOVYd";
var _7 = "nAT32NF0sQol5WQpeuJn";
var _8 = "o59QjHKmjmZgvOXwaLv8";
var _9 = "s_9e0stL8x18peWYzaVC";
var _a = "mNSgrTAJgjReiNJD_do0";
var _b = "X4d1nZTsaGEglxVqLevH";
export { _1 as "backbutton", _2 as "border", _3 as "bottom_buttons", _4 as "content", _5 as "crossholder", _6 as "mainTitle", _7 as "page", _8 as "reel", _9 as "rotate", _a as "specs", _b as "title" }
